.wrapper {
  position: relative;

  .inputDefault{
    height: 36px;
    width: calc(100% + 20px);
    border: 2px solid #3547C8;
    border-radius: 3px;
    margin-left: -10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #324150;
    
    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #BEC2C6;
    }
  }

  .inputDefaultError{
    &::placeholder {
      color: #E2574C;
    }
  }
}

.noActiveWrapper {
  width: calc(100% + 20px);
  margin-left: -10px;
  padding: 6px 10px;
  margin-bottom: 6px;
  cursor: pointer;

  .noActiveText {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #324150;
  }

  .noActiveTextPlaceholder {
    color: #BEC2C6;
  }

  .noActiveTextError {
    color: #E2574C;
  }

}

.noActiveWrapperError {
  height: 36px;
  border: 2px solid #E2574C;
  border-radius: 3px;
  cursor: text;
}

.nPCursor {
  cursor: text;
}
