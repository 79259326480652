.renderTable {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;

  tr {
    border: 1px solid #EAEDF0;
  }

  th {
    color: #324150;
    font-size: 14px;
    font-weight: 600;
  }
  
  .headTable {
    height: 38px;
    background-color: #EAEDF0;

    .rowCheck {
      padding-left: 0;
      width: 40px;
      text-align: center;
    }
  }

  .rowTable {
    height: 44px;

    td {
      padding-right: 16px;
    }

    .tdCheckLoad {
      width: 40px;
      min-width: 40px;
      padding-right: 0;
    }

    .rowCheckLoad {
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rowName {
      display: flex;
      align-items: center;
    }

    .rowNameCustomContainer {
      display: flex;
      align-items: center;  
    }
    .rowNameCustom {
      display: flex;
      align-items: center;
      background: linear-gradient(90.21deg, #F8F8F8 0.18%, rgba(250, 250, 250, 0.5) 50.52%, #F8F8F8 99.82%);
      border-radius: 12px;
      padding: 6px 6px 6px 4px;

      &+.rowNameCustom {
        margin-left: 4px;
      }
    }
    
    .rowCircles {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
