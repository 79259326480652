.main-wrapper {
  padding: 30px 40px;

  p {
    font-size: 14px;
    font-weight: 600;
    
    &+p {
      margin-top: 25px;
      margin-bottom: 0;
    }
  }
}

.footer-buttons{
  .cancel-button {
    border-radius: 5px;
    width: 123px;
  }

  .confirm-button {
    border-radius: 5px;
    width: 184px;
  }

  .cancel-button-not-permission {
    border-radius: 5px;
    width: 110px;
    cursor: pointer !important;
  }
}

.Modal {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  width: 311px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 55px;
  top: 202px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.ModalBlocked {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  width: 311px;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 55px;
  top: 202px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .titleContent {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: #324150;
  }

  .textContent {
    margin-top: 8px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8697A8;
  }

  .close {
    width: 71px;
    height: 36px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #EAEDF0;
    border: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #324150;
    cursor: pointer;
  }
}

.contentBlockedWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tag {
    width: 122px;
    height: 18px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-size: 8px;
    line-height: 11px;
    font-weight: 700;
    text-transform: uppercase;

    i {
      margin-right: 7px;
    }
  }

  .titleBlockedContent {
    margin-top: 15px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: #324150;
  }

  p {
    margin-top: 8px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: #8697A8;

    &+p {
      margin-top: 12px;
      margin-bottom: 0;
    }
  }

  .close {
    width: 71px;
    height: 36px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #EAEDF0;
    border: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #324150;
    cursor: pointer;
  }
}


