.renderTable {
  width: 100%;
  border: 1px solid #EAEDF0;
  
  tr {
    border: 1px solid #EAEDF0;
    background-color: #FFFFFF;
  }

  th {
    background-color: #EAEDF0;
    color: #324150;
    font-size: 14px;
    font-weight: 600;
  }
  
  .headTable {
    height: 78px;
    background-color: #EAEDF0;

    .rowCheck {
      padding-left: 0;
      width: 40px;
      text-align: center;
    }

    .rowSeparator {
      height: 6px;
      border-left: 1px solid #F8FAFB !important;
      border-right: 1px solid #F8FAFB !important;
    }
  }

  .rowTable {
    height: 64px;

    td {
      padding-right: 16px;
    }

    .tdCheckLoad {
      width: 40px;
      min-width: 40px;
      padding-right: 0;
    }

    .rowCheckLoad {
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rowName {
      display: flex;
      align-items: center;

      .rowPaper {
        width: 100%;
        display: flex;
        border: 2px solid #EAEDF0;
        border-radius: 2px;
        padding: 10px 12px;  
      }
    }

    .rowSeparator {
      height: 6px;
      border-left: 1px solid #F8FAFB !important;
      border-right: 1px solid #F8FAFB !important;
    
      &:first-child {
        border-bottom: 1px solid red;
      }
      &:last-child {
        border-bottom: 1px solid #F8FAFB !important;
      }
    }
  }
}
