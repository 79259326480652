.ObraArquivada {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 70px;



  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    
    span {
      margin-left: 24px;
      margin-right: 26px;
      
      span {
        margin-left: 0px;
        margin-right: 0px;
        color: #FFF;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 32px;
      }
    }

    strong {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #44566C;
      opacity: 0.5;
    }
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #8697A8;
    margin-left: auto;
    margin-right: 36px;
    cursor: pointer;
  }

  .linkDesarquivar:hover {
    color: #4e525a;
  }
}

.textWrap{
  padding: 30px;
}