.formaInput{
  background: #FFFFFF;
  border: 2px solid #EDEFF2;
  border-radius: 2px
}

.boxDadosEmpresa {
  width: 100%!important;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;

  .DadosEmpresaLeft {
    width: 345px!important;
    padding: 20px 0 0 30px;
  }

  .DadosEmpresaRight{
    overflow-y: scroll; 
    padding: 0 30px 0 0;
    width: 495px!important;
  }

  .DadosEndereco{
    padding: 0 30px;
    width: 100%;
  }

  .icon{
    float: right;
    user-select: none;
    cursor: pointer;
  }

  .boxData{
    padding: 20px 30px 0 0;


    label.fields {
      margin-bottom: 10px!important;
    }
    label.fields > div {
      font-size: 12px!important;
    }

    .fields {
      input {
        height: 42px !important;
        font-family: Open Sans!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 16px!important;
        color: #44566C!important;
      }
    }
  }

  .title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;
  }

  .data{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #44566C;
  }
  
  #titleDetails{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    color: #44566C;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
  }

  .changeImageWrapper {
    width: 245px;
    height: 174px;
    background: #2EA3F2;
    opacity: 0.75;
    border-radius: 5px;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin-right: 30px;
    background-size: cover;
    background-repeat: no-repeat;

    .changeImageLabel {      
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
    }

    &:hover > span {
      filter: blur(2px);
    }
  }      
  
  .empresaImg {
    width: 245px;
    height: 174px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border-radius: 5px;

    span {
      height: 135px;
      width: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .editHover {
      position: absolute;
      color: #FFF;
      display: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      justify-content: center;
      align-items: center;
      flex-flow: column;
  
      p {
        margin-bottom: 0px;
      }
    }
  
    &:hover {
      .editHover {
        display: flex;
      }
  
      > span {
        filter: blur(2px);
      }
    }
  
  }
}
