.contentWrapper {
    padding: 32px;
    height: 100%;
    width: 100%;
    display: flex;
}

.halfWrapper {
    width: 50%;
    height: 100%;
}

.titleLogin {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #44566C;

    padding-bottom: 22px;
}

.subtitleLogin{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    
    color: #44566C;
    padding-bottom: 30px;
}

.formLoginWrapper {
    padding-left: 40px;

    label {
        padding-bottom: 12px;
    }

    .logo-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px !important;
    }
}

.link {
    color: #3547C8;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding-left: 2px;

    &:hover {
      color: #0081FF;
      text-decoration: underline;
    }
}

.novoCadastro {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #8697A8;

    .redirectLink {
        color: #3547C8;
    
        &:hover {
            color: #0081FF;
            text-decoration: none;
        }
    }
}

.esqueceuSenha {
    margin-left: auto;
    margin-bottom: 20px;

    label span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #8697A8;
    }    
    
    a {
        float: right;
        line-height: 21px;
    }
}

.passwordWrapper {
    position: relative;

    .passwordFeedback {
        position: absolute;
        right: -153px;
        top: 0px
    }
}

.containerWrapper {
    div {
        section {
            overflow: visible;
        }
    }
}