.defaultInput {
  width: 100%;
  min-height: 72px;
  height: auto;
  max-height: 144px;
  overflow: visible;
  border: 1px solid #3547C8;
  border-radius: 3px;
  resize: none;
  overflow: scroll;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #324150;
  margin-top: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
  
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #BEC2C6;
  }
}

.paddingIcon {
  padding-left: 42px !important;
}

.errorInput {
  color: #E2574C;
  padding-left: 10px;
  font-weight: 600;
  height: 18px;
}

.disabledInput {
  color: #8697A8;
}

.noActiveContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .noActiveWrapper {
    width: 100%;
    min-height: 72px;
    max-height: 144px;
    background-color: #EAEDF0;
    border: none;
    border-radius: 3px;
    padding: 8px 10px;
    overflow: hidden;
    margin-bottom: 2px;
    margin-top: 8px;
    cursor: pointer;
  
    .noActiveText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      color: #324150;
    }
    
    .noActiveTextPlaceholder {
      color: #BEC2C6;
    }
  }

  .noActiveWrapperOpen {
    max-height: 232px;
    overflow-y: scroll;
  }

  .noActiveBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #0081FF;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .noActiveWrapperVisualization {
    background-color: #FFFFFF;
    max-width: 302px;
    
    &:hover {
      background-color: #EAEDF0;
    }
  }

  .noActiveWrapperVisualizationShort {
    max-width: 250px;
  }
}

.nPCursor {
  cursor: text !important;
}
