.usuariosConvidarWrapper {
  padding: 27px 31px 10px 34px;
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.newUsersWrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  .inputEmail {
    margin-bottom: 0;
    margin-right: 15px;
    width: 100%;

    input {
      padding-left: 24px;
    }
  }
  .buttonNewUser {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;

    i {
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }

    .iconeNewUser{
      border: none!important;
      font-size: 16px !important;
      font-weight: bold!important;
      align-items:flex-end;
    }

    .iconeNewUserDisaled{
      border: none!important;
      font-size: 16px !important;
      font-weight: bold!important;
      align-items:flex-end;
    }
    &{
      border: none !important;
    }
  }
}

.selectRole {
  width: 200px !important;
  margin-right: 13px !important;
}

.permissionsHintWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 25px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #bdbdbd;
  cursor: default;
}

.titleSharedUserList {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566c;
  padding-bottom: 19px;
}

.sharedUserItem {
  margin-top: 6px;
  margin-right: 0;
  width: 100%;
}

.hintText {
  padding: 23px;
  width: 323px;
}

.invitedUserWrapper {
  padding: 9px 19px 10px 28px;
  display: flex;
  align-items: center;

  button {
    margin-left: 13px;
  }
}

.invitedUserName,
.sharedUserName {
  padding-left: 26px;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697a8;
}

.sharedRoleName {
  flex: 1 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697a8;
  border: 2px solid #edeff2 !important;
  height: 35px;
  max-width: 160px;
  margin-right: 10px;
  text-align: center;
  padding-top: 6px;
  border-radius: 2px;
}

.shareButton {
  width: 147px;
  font-size: 12px !important;
  justify-content: center;
  align-items: center;
  display: flex !important;

  &.shared {
    border: 2px solid #09b66d;
    color: #09b66d !important;
    &:hover,
    &:focus {
      border-color: #079e5f;
    }
  }

  &:hover {
    background: #edeff2 !important;
  }

  > span {
    display: flex !important;
    align-items: center;
  }
}

.waitingButton {
  height: 32px;
  margin-left: 13px;

  display: flex;
  .waitingTitle {
    padding: 6px 18px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #fdbf5e;
    user-select: none;
    cursor: default;

    border: 2px solid #fdbf5e;
    border-radius: 2px;
  }

  .buttonRemoveUser {
    width: 34px;
    border: none;
    margin: 0;
    padding: 3px 0 0 0;
    text-align: center;
  }
}

.iconInfo {
  transition: color ease 0.3s;
}
