.WrapperItem {
  width: 100%;
  border: 1px solid #EAEDF0;
  border-radius: 5px;
  display: flex;

  padding: 8px 25px 8px 15px;
  margin-bottom: 10px;
  cursor: pointer;

  > span {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 100%;
    width: 100%;
  }

  p { 
    margin: 0;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 0 15px;

    .username {
      font-family: 'Open Sans';
      font-weight: 500;
      font-size: 12px;
      color: #324150;
      margin-bottom: 5px;

      span {
        font-weight: 700;
      }
    }

    .filename {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      color: #8697A8;
      text-transform: uppercase;
    }

    .subcontent {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      .date {
        font-family: 'Open Sans';
        font-weight: 500;
        font-size: 10px;
        line-height: 150%;
        color: #8697A8;
        padding-right: 12px;
      }

      .tag {
        color: #0081FF;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
        padding: 3px 10px;
      }
    }
  }

 
}