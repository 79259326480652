.tag {
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.loader {
  margin-right: 5px;
  display: inline-block;
  border: 3px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 3px solid #8697A8;
  border-radius: 50%;

  width: 15px;
  height: 15px;
}

.secondary-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 6px 8px;
  border: none;
  background-color: #EAEDF0;
  border-radius: 6px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}