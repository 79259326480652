.PlanContainerWrapper {
  margin: 20px 10px 0 20px;
}

.BoxContainer {
  display: flex;
  flex-direction: column;

  padding: 25px;
  
  width: 244px;
  height: 282px;

  background: #FFFFFF;
  border: solid 1px #EAEDF0;
  border-radius: 5px;
  overflow: hidden;
}