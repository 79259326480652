.wrapper {
  width: 100%;
  height: calc(100% - 32px);
  
  .mainWrapper {
    display: flex;
    // flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    
    .viewerWrapperDrawerOpen {
      width: calc(100% - 350px);
      height: 100%;
    }

    .viewerWrapper {
      width: 100%;
      height: 100%;
    }
  }  
}
