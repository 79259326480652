.MinhaContaWrapper {
    display: flex;
    height: auto;
    width: 100%;
    flex-flow: row wrap;
    padding-bottom: 20px;
}

.MinhaContaSkeleton {
    padding: 0 30px 0 30px;
}