.tableContainer {
  margin-right: 42px;
  margin-left: 38px;
}

.fileTable {
  margin-left: 20px;
  width: 1054px;
}

.tooltipModifiedBy {
  padding: 22px 28px 16px 28px;

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #44566c;
    margin-bottom: 12px;
  }

  .value {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: #8697a8;
  }

  .margin-top {
    margin-top: 21px;
  }

  .padding-left {
    padding-left: 12px;
  }
}

.menuDropdown {
  min-width: 120px;
  padding: 15px 0;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: auto;

  .options {
    padding: 10px 15px 10px 15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666666;
    cursor: pointer;

    &:hover {
      background: #f8fafb;
    }
  }
}

.solicitedDate {
  cursor: default;
  &:hover {
    color: #3547C8;
    text-decoration: underline;
  }
}

.filterSolicitadoPor {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 8px;
  }
}

.menu-container {
  width: 250px;
  
  .menu {
    display: flex;
    align-content: center;
    justify-content: space-between;

    .text {
      margin-bottom: 0px;
      user-select: none;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;

      display: flex;
      align-items: center;

      color: #666666;
    }
  }
}

.tableContainer {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead tr th:first-child {
    width: 68px !important;
    padding-left: 22px !important;
  }
  thead tr th {
    padding-right: 0px !important;
  }
  tbody {
    display: block;
    max-height: calc(100vh - 364px);
    overflow-y: auto;

    tr td:first-child {
      width: 68px !important;
    }
    tr td {
      padding-right: 20px !important;
    }
  }

  @media screen and (max-width: 1366px) {
    .columnPlottingDescription{
      width: 15vw;
    }
    .columnfileCollumn{
      width: 25vw;
    }
    .columnRevision, .columnRequestDate, .columnStatus{
      width: 10vw;
    }
  }

  @media screen and (min-width: 1367px) {
    .columnPlottingDescription{
      width: 10vw;
    }
    .columnfileCollumn{
      width: 30vw;
    }
    .columnRevision, .columnRequestDate, .columnStatus{
      width: 10vw;
    }
  }

  .nameCollumn {
    display: flex;
    align-items: center;
    user-select: none;
  }

  .requestName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .fileCollumn {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.tableLoading {
  margin-right: 42px;
  margin-left: 38px;
}

@media print {
  .tableContainer {
    margin: 0px !important;

    tbody {
      max-height: unset !important;
    }
  }

  .tableListaArquivos {
    display: none;
  }
}

.tableCollapsed {
  thead tr th:first-child {
    padding-left: 25.5px !important;
  }
}
