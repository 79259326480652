.ObrasWrapper {
  display: flex;
  height: auto;
  width: 100%;
  flex-flow: row wrap;
  padding-bottom: 50px;

  .buttonObra {
    width: 46px;
    height: 46px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ListaObrasArquivadas {
  width: 100%;
  padding-right: 30px;
  padding-left: 20px;

  section {
    opacity: 0.5;
  }
}

.withoutConstruction {
  margin-top: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .title {
    width: 418px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin: auto;
    color: #44566c;
    text-align: center;
    padding-top: 20px;
  }
}

.buttonTour {
  width: 323px;
  margin-top: 34px;
  background: transparent !important;
}

.skeleton{
  padding: 3px 36px 23px 25px;
}