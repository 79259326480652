.actions-container {
  height: 46;
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }
}

.buttonObra {
  width: 46px;
  height: 46px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 61px);
}

.search-input {
  z-index: 99999;
  margin-right: 12px;
}

.form-container {
  height: 32px;
}