.formaInput{
  background: #FFFFFF;
  border: 2px solid #EDEFF2;
  border-radius: 2px
}

.boxDetalhesConta {
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 30px;
    color: #324150;
  }
  .DetalhesContaLeft {   
    padding-top: 20px;
    padding-left: 20px;
    width: 50%;
    .ImgUpgrade{
      width: 220px;
      height: 280px;
    }
  }

  .DetalhesContaRight{
    padding: 0 30px 0 0;
    width: 80%;

    .fieldToConfirmLabel{
      width: 101px;
      height: 15px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      color: #8697A8;
    }
    .fieldToConfirm{
      width: 452px;
      height: 42px;
      margin-top: 5px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      background: #EFEFEF;
      border: 2px solid #EDEFF2;
      border-radius: 2px;
      padding:10px 20px;
      color:#44566C;
    }
    .fieldToConfirmDuple{
      width: 221px;
      height: 42px;
      margin-top: 5px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      background: #EFEFEF;
      border: 2px solid #EDEFF2;
      border-radius: 2px;
      padding:10px 20px;
      color:#44566C;
    }
  }

  .DetalhesConta{
    padding: 0 30px;
    width: 50%;
  }

  .icon{
    float: right;
    user-select: none;
    cursor: pointer;
  }

  .boxData{
    padding: 20px 30px 0 0;
  }

  .title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;
  }

  .data{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #44566C;
  }
  
  #titleDetails{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    color: #44566C;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
  }
  
  .contaImg {
    width: 245px;
    height: 174px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border-radius: 5px;

    span {
      height: 135px;
      width: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .editHover {
      position: absolute;
      color: #FFF;
      display: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      justify-content: center;
      align-items: center;
      flex-flow: column;
  
      p {
        margin-bottom: 0px;
      }
    }
  
    &:hover {
      .editHover {
        display: flex;
      }
  
      > span {
        filter: blur(2px);
      }
    }
  
  }
}

.confirmNewPasswordFeedback {
  height: 50px !important;
  width: 190px !important;

  div {
      width: 100%;
  }
}



