.BoxContainerWrapper {
  margin: 20px 10px 0 20px;
}

.BoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  width: 244px;
  height: 282px;

  background-color: #FFFFFF;
  border: solid 1px #EAEDF0;
  border-radius: 5px;
  overflow: hidden;
}