.containerWrapper {
    display: none;
    .headerWrapper {
        font-family: "Open Sans", sans-serif;
        padding-bottom: 2px;
        margin-bottom: 15px;
        border-bottom: 3px solid #3547C8;
        h3 {
            color: #324150;
            font-weight: bold;
        }

        p {
            color: #bec2c6;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .tableWrapper {
        thead {
            font-size: 11px;
            tr th:first-child div:first-child {
                padding-left: 10px;
            }
            tr th:first-child {
                width: 210px;
            }

            .collumnStatus {
                width: 90px;
            }
        }

        tbody {
            tr td {
                font-size: 11px !important;
            }
            tr td:first-child {
                padding-left: 21px !important;
                width: 210px;
            }

            .collumnStatus {
                width: 90px;
            }
        }

        [class*="orderIcon"] {
            display: none;
        }

        .flagStatus {
            background: gray;
            width: 9px;
            height: 9px;
            float: left;
            border-radius: 50%;
            position: relative;
            top: 4px;
            margin-right: 5px;
        }
    }
    .footerWrapper {
        position: fixed;
        bottom: 0px;
        width: 100%;

        .imgFooter {
            float: left;
            width: 50%;
            img {
                float: left;
            }

            p {
                font-family: "Open Sans", sans-serif;
                font-weight: bold;
                color: #bec2c6;
                font-size: 16px;
                position: relative;
                top: 2px;
                left: 6px;
            }
        }

        .hourFooter {
            text-align: right;
            padding-right: 15px;

            p {
                font-family: "Open Sans", sans-serif;
                font-weight: bold;
                color: #bec2c6;
                font-size: 16px;
                position: relative;
                top: 2px;
                left: 6px;
            }
        }
    }
}

@media print {
    .containerWrapper {
        display: block;

        .footerWrapper{
            position: relative;
            padding-top: 20px;
        }
    }
}
