.ant-table-body table {
  border: 1px solid #EAEDF0;
  border-radius: 0 !important;
}

.ant-table-thead > tr > th {
  background-color: #EAEDF0 !important;
  font-family: Open Sans;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #44566C !important;
  height: 38px !important;
  border-radius: 0 ;
  padding: 0 !important;
}

.ant-table-cell-scrollbar {
  display: none;
}

.ant-table-thead > tr > th .ant-table-header-column {
  height: 100%;
  width: 100%;
}

.ant-table-thead > tr > th .ant-table-header-column > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ant-table-thead > tr > th .ant-table-header-column > div > .ant-table-column-title {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ant-table-tbody > tr > td {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px; 

  background-color: #FFF;
  color: #8697A8;
  padding: 0 !important;
  height: 44px;
}

.ant-table-thead > tr > .ant-table-selection-column,
.ant-table-tbody > tr > .ant-table-selection-column {
  padding: 0 36px 0 19px !important;
}

.ant-table-tbody > tr:last-child > td {
  border: 0;
}

.ant-table-tbody tr:hover > td {
  background-color: #F8FAFB !important;
}

table .ant-table-thead  tr:first-child  th:first-child, table .ant-table-thead  tr:last-child  th:last-child {
  border-radius: 0;
}

.ant-table-body .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background-color: #f4f6f9 !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-table-tbody > tr > .ant-table-row-level-1 > td {
  background-color: red !important;
}

.ant-table-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
  color: #8697a8 !important;
}