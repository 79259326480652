.menuColumn {
  width: 150px;
}

.tagItem {

  .tag {
    padding: 2px 12px !important;
    margin: 2px 2px !important;
    cursor: pointer;
  }
}

.tagRender {
  display: flex !important;
  padding: 0 0 0 12px !important;
  align-items: center;
  cursor: pointer !important;
}

.ativo {
  width: 76px;
}

.inativo {
  width: 87px;
}

.tagPending {
  padding: 2px 12px !important;
}
