.spacedRows table {
  border-collapse: separate; 
  border-spacing: 0 3px !important;
  border: none;
}

.spacedRows table tr {
  height: 51px;
}

.spacedRows table tr td, .spacedRows table tr th {
  border-top: 1px solid #EAEDF0 !important;
  border-bottom: 1px solid #EAEDF0 !important;
}

.spacedRows table tr td:first-child, .spacedRows table tr:first-child th:first-child {
  border-left: 1px solid #EAEDF0 !important;
  border-radius: 5px 0 0 5px;
}

.spacedRows table tr td:last-child, .spacedRows table tr:last-child th:last-child {
  border-right: 1px solid #EAEDF0 !important;
  border-radius: 0 5px 5px 0;
}

.spacedRows .ant-checkbox-checked .ant-checkbox-inner::after {
  top: 25%;
  left: 14%;
  width: 9px;
  border: 3.4px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(.6) translate(-50%, -50%);
}

.spacedRows .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 10px;
  transform: translate(-50%, -50%) scale(.7);
}


.spacedRows .ant-radio-checked .ant-radio-inner::after {
  border-spacing: 0 0 !important;
}

.spacedRows tr > .ant-table-selection-column {
  padding: 0px !important;
}

th.ant-table-selection-column {
  margin-right: 20px;
  width: 40px;
}