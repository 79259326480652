.wrapperIcon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8697A8;
  }
}