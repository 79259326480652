.selectWrap {
  position: relative;
  height: fit-content;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 2px;
}
.iconSelectBeforeText {
  position: absolute;
  display: flex;
  top: 10%;
  padding: 5px 0;
  right: 15px;
  color: #bdbdbd;
  text-align: center;
}
.iconSelect {
  position: absolute;
  top: 10%;
  text-align: center;
  right: 21px;
  pointer-events: none;
  transition: ease 0.2s all;
  color: #324150;

  &.focus {
      transform: rotate(180deg);
  }
}
.option {
  height: 48px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #0081FF1A;
  }
}

.SelectText {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: #324150;
}

.iconInput {
  position: absolute;
  left: 12px;
  top: 6px;
  margin-top: -12px;
  z-index: 1;
  background: #44566c;
}

.noActiveWrapper {
  width: 100%;
  background-color: #FFFFFF;
  border: none;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
  
  &:hover {
    background-color: #EAEDF0;
  }

  .noActiveText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #BEC2C6;
  }

  .noActiveTextError {
    color: #E2574C;
  }
}

.noActiveWrapperError {
  height: 34px;
  border: 2px solid #E2574C;
  border-radius: 3px;
  cursor: text;

  &:hover {
    background-color: #FFFFFF;
  }
}

.customRenderWrapper {
  width: 298px;
  max-height: 116px;
  padding: 4px 0;
  margin: 0;
  overflow-y: scroll;
 
  .customRenderItem {
    cursor: pointer;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: #324150;

    &:hover {
      background-color: #0081FF1A;
    }
  }
}

.nPCursor {
  cursor: default;
}

.nPCursorText {
  cursor: text;
}

.emptyText {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
}
