.wrapper {
  padding: 34px 40px;
  display: flex;
  align-items: center;
}

.progress-circle-resources {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #BEC2C6;
  margin-bottom: 4px;

  span {
    color: #324150;
  }
}

.progress-circle-text {
  font-size: 12px;
  font-weight: 400;
  color: #BEC2C6;
  line-height: 12px;
  text-align: center;
}

.progress-text {
  margin-left: 28px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #44566C;
  
  p {
    margin: 0;

    &:first-child {
      margin-bottom: 16px;
    }
  }
}

.progress-render-footer-buttons {
  .progress-render-cancel-button {
    width: 123px;
    border-radius: 5px;
  }

  .progress-render-confirm-button {
    width: 138px;
    margin-left: 5px;
    border-radius: 5px;
  }
}
