.Wrapper {
  .tipoOptionsWrap {
    padding: 15px 60px 15px 24px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 35px;
    cursor: pointer;
  
    &:hover {
      background-color: #F8FAFB;

      p {
        color: #3547C8;
      }
    }
  
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #44566C;
      margin: 0;
    }
  }
}