.adm-main-wrapper-updown {
  padding: 10px 40px 30px 40px;

  .divider-updown-wrapper {
    display: flex;

    div {
      width: 80px;

      &+div {
        margin-left: 80px;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
      }

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  
}

button.buttons-upordown-modal {
  border-radius: 4px;
}
