.permissoesWrapper {
  display: flex;
  margin: 10px 0;
}

.container {
  background: #ffffff;
  border: 1px solid #eaedf0;
  box-sizing: border-box;
  border-radius: 5px;

  height: 50px;
  display: flex;
  align-items: center;
}

.containerButton {
  padding: 0 15px;
  cursor: pointer;
  margin-left: 9px;
  display: flex;
  align-items: center;

  p {
    font-size: 10px;
    color: #b3c0ce;
    display: none;

    &.selected {
      color: #3547C8;
    }
  }
}

.containerUsername {
  width: 438px;
  padding-left: 28px;
}

.username {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8697a8;
  padding-left: 26px;
}

@media (max-width: 1024px) {
  .containerButton {
    display: block;
    align-items: center;
    text-align: -webkit-center;
    padding-top: 5px;
    i {
      font-size: 15px !important;
    }
    p {
      display: block;
    }
  }
}
