.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0 150px 0;
}

.wrapperContentTop {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  border: 1px solid #BEC2C6;
  border-radius: 10px;
  margin-bottom: 10px;

  .spanContent {
    width: 120px;
    height: 10px;
    border-radius: 10px;
    background-color: #EAEDF0;
  }  

  i {
    margin-left: 16px;
  }
}

.wrapperContent {
  width: 152px;
  height: auto;
  padding: 0 18px 5px 18px;
  margin-top: 17px;
  border-bottom: 1px solid #BEC2C6;
}

.spanContent {
  width: 120px;
  height: 10px;
  border-radius: 10px;
  background-color: #EAEDF0;
}

.wrapperContentBottom {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  .textBottomHead {
    color: #324150;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  .textBottomFooter {
    color: #8697A8;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}