.Search {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 70px;
  margin-right: 260px;
  background-color: #F8FAFB;
  border-radius: 5px;

  input { 
    width: inherit;
    padding: 1rem;
    border: none;
    background-color: #F8FAFB;
    border-radius: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8697A8;
    }
  }

  .icon {
    box-sizing: border-box;
    display: table-cell;
    font-size: .8rem;
    line-height: 0;
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 1%; 
  }
}