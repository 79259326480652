.wrapper {
  background: rgb(255, 255, 255, 0.75) ;
  backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  height: calc(100vh - 129px);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    .header {
      background-color: #FFC700;
      width: 59px;
      height: 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 8px;
        line-height: 14px;
        font-weight: 600;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 7px;

      h3 {
        color: #324150;
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      p {
        text-align: center;
        color: #8697A8;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        margin-bottom: 32px;
      }
    }

    img {
      max-width: 220px;
    }
    
    .button-confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #324150;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      margin-top: 32px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 0;
      }
    }
  }
}