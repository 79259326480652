.main-wrapper {
  padding: 30px 28px 40px 40px;

  p {
    font-size: 14px;
    font-weight: 600;

    &+p {
      margin-top: 25px;
    }
  }
}

.footer-buttons{
  .exit-button {
    border-radius: 5px;
    width: 90px;
  }

  .cancel-button {
    border-radius: 5px;
    width: 190px;
  }
}
