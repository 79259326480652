.ant-select-dropdown-menu-item {
  height: 48px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16.34px !important;
  color: #324150 !important;
}
.ant-select-dropdown-menu-item-selected {
  height: 48px !important;
  width: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16.34px !important;
  color: #324150 !important;
}
.ant-select-selection__choice {
  border-radius: 16px !important;
  background-color: #EAEDF0 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16.34px !important;
}
.ant-select-selection__choice:hover {
  background-color: #E2574C1F !important;
}
.ant-select-remove-icon:hover {
  color: #E2574C !important;
}