.ant-select .ant-select-selection:focus,
.ant-select .ant-select-selection:active {
  border: 2px solid #3547C8 !important;
  box-shadow: none !important;
}

.ant-select .ant-select-selection {
  border: 2px solid #EDEFF2;
  border-radius: 2px;
}

.ant-select-selection__rendered {
  margin-left: 19px !important;
}

.ant-select .ant-select-selection__placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.5px !important;
  color: #BEC2C6;
}

.ant-select-focused .ant-select-selection,
.ant-select-open .ant-select-selection {
  border: 2px solid #3547C8 !important;
  box-shadow: none !important;
}

.select-custom {
  height: 32px;
}

.select-custom > .ant-select-selection--single > .ant-select-selection__rendered {
  display: flex !important;
  align-items: center !important;
}
