.FloatingWrapper {
  width: 150px;
  height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5px;
    margin-right: 3px;
  }
  
  .validationGroup {
    width: 150px;
    height: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    
    p {
      color: #E2574C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 25px;
      margin-left: 10px;
    }
  }
}

.ConfirmWrapper {
  width: 185px;
  height: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}