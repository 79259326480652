.ant-select .ant-select-selection:focus,
.ant-select .ant-select-selection:active {
  border: 2px solid #3547C8 !important;
  box-shadow: none;
}

.ant-select .ant-select-selection {
  border: 2px solid #EDEFF2;
  border-radius: 2px;
}

.ant-select-selection-selected-value {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697A8;
}

.ant-select-selection__rendered {
  margin-left: 19px !important;
}