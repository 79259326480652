.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-collapse-header {
  margin-left: -19px !important; 
  align-items: center !important;
}
