.contentWrapper {
  display: flex;
  align-items: center;
  padding: 10px 32px;
  height: 51px;
  margin: 10px 0;
  cursor: pointer;
  user-select: none;

  background: #ffffff;
  border: 1px solid #eaedf0;
  border-radius: 5px;
}

.disciplinaTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  color: #44566c;
  margin-left: 18px;
  padding-top: 4px;
}

.iconWrapper {
  margin-left: 35px;
}

.disciplinaSelected {
  background: rgba(0, 129, 255, 0.05);
}

.disciplinaDisabled {
  opacity: 0.5;
}
