.Grid {
  display: flex;
  width: 100%;
  padding: 25px 30px;

  .grid-left {
    width: 100%;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin-top: 12px;
      line-height: 22px;

      span {
        color: #FFC700;
      }
    }

    p {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
    }

    .grid-progress-bar {
      width: 100%;
      padding: 25px;
      background-color: #FFFFFF;
      border: 1px solid #EAEDF0;
      border-radius: 5px;

      .title-progress-bar {
        font-size: 12px;
        font-weight: 600;
        color: #8697A8;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #EAEDF0;
      }
    }
  }

  .grid-right {
    max-width: 350px;
    margin-left: 15px;

    button {
      width: 100%;
      border-radius: 5px;
    }
  }

  .divider-top {
    padding-bottom: 15px;
    border-bottom: 1px solid #EAEDF0;

    .title {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      color: #8697A8;
    }
  }

  .ticket {
    display: flex;
    align-items: center;
    height: 31px;
    background-color: #F8FAFB;
    border-radius: 3px;
    margin: 15px 0;
    padding-left: 10px;

    .text-ticket {
      strong {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #324150;
      }
    }
  }

  .divider-middle {
    padding-bottom: 5px;
    margin-bottom: 15px;
    border-bottom: 1px solid #EAEDF0;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    color: #8697A8;
    margin-bottom: 10px;

    strong {
      font-size: 12px;
      font-weight: 400;
      color: #324150;
    }

  }
  
  .text-link {
    margin-bottom: 0;
  }
}

.box-header-button{
  height: 36px;
  .header-button {
    display: flex;
    align-items: center;
    border-radius: 5px;

    .header-button-icon {
      margin-right: 10px;
      width: 12px;
      height: 12px;
    }

    span {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
