.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-popover-arrow {
  display: none !important;
}

#deleteBtn {
  border-radius: 5px;
  background-color: #E2574C !important;
  color: #FFFFFF !important;
  margin-right: 4px !important;
  border-color: transparent !important;
}