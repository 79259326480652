.fileDropWrap {
  position: relative;
  min-height: 100%;
}

.dragInfoWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #000000;

  * {
    pointer-events: none;
  }
}