.drawer {
  padding: 24px 19px 0 19px !important;
}

.iconToggleWrap {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .iconChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    color: #BEC2C6 !important;
    
    &:hover {
      color: #324150 !important;
    }
    
    &.iconActive {
      transform: rotate(180deg);
    }
  }
}

.iconCardWrap {
  width: 48px;
  height: 208px;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  
  .iconCardChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: 6px;
    color: #8697A8 !important;
    
    &.iconActive {
      background-color: #EAEDF0 !important;
      color: #324150 !important;
    }
  }

  .iconCardChildrenActivie {
    color: #BEC2C6 !important;
  }
}