.ant-tooltip-inner {
  padding: 10px;
  background-color: #FFFFFF !important;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #828282 !important;
}

.ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}

.ant-tooltip .ant-tooltip-arrow:before {
  background: white !important;
}

.ant-alert-warning {
  display: flex;
  align-items: center !important;
}