.Grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 102px;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;
  border-radius: 5px;
  margin-bottom: 15px;

  .grid-left {
    display: flex;
    align-items: center;

    .grid-left-image {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 12px 12px 15px;
    }

    .grid-left-children {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px 15px 15px 0;
    }
  }

  .grid-right {
    button {
      display: flex;
      align-items: baseline;

      margin: 35px;
      padding: 8px 0;
      color: #324150;
      font-size: 12px;
      font-weight: 600;
      border: none;
      border-radius: 2px;

      span {
        display: flex;
        align-self: center;
        justify-content: center;

        &+span {
          margin-right: 15px;
        }
      }

      i {
        margin-right: 10px;
        margin-left: 16px;
      }
    }
  }
}