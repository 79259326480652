.Header {
  height: 60px;
  width: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;

  .headerContent {
    display: flex;
    align-items: center;
    justify-content: center;

    strong {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 22px;
      color: #aab2bd;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
    }

    .icon {
      cursor: pointer;
      user-select: none;
      margin-top: 2px;
      margin-left: 15px;
      user-drag: none;
    }

    div {
      overflow: visible;
      padding-right: 0;

      span {
        font-family: Open Sans;
        cursor: pointer;
        user-select: none;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 22px;
        color: #44566c;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .iconHeader {
      margin-top: 2px;
      margin-left: 30px;
      margin-right: -20px;
    }
  }

  .actions {
    padding-right: 60px;
  }

  .custom-actions {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 24px;
    margin-top: 5px;
  }
}

@media print {
  .Header, .Header .headerContent {
    display: none;
  }
}