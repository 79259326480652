.box-form-wrapper {
  width: 100%;
  margin-top: 15px;
  background-color: white;
  border: 1px solid #EAEDF0;
  border-radius: 5px;
  padding: 25px;

  .title-form {
    width: 100%;
    margin-bottom: 15px;

    h4{
      font-size: 12px;
      font-weight: 600;
      color: #8697A8;
    }
  }

  .form-wrapper {
    width: 100%;

    .field-wrapper {
      display: flex;
      margin-bottom: 5px;

      div {
        width: 100%;

        &+div {
          margin-left: 15px;
        }
      }

    }
  }

  .footer-wrapper {
    .button-data-change {
      margin-bottom: 0;
      color: #0081FF;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
    }

    .footer-button {
      border-radius: 5px;
      cursor: pointer;

      &+button {
        margin-left: 15px;
      }
    }
  }
}

.content {
  width: 100%;
  height: 1px;
  background-color: #EAEDF0;
  margin-bottom: 20px;
}
