.main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 53px;

  background: #FFFFFF;
  border: solid 1px #EAEDF0;
  border-radius: 5px;

  padding: 9px 20px 9px 30px;
  margin-top: 6px;

  .header {
    display: flex;
    align-items: center;
  }

  .divider {
    display: flex;
    align-items: center;
    
    .middle {
      display: flex;
      border: 2px solid #EAEDF0;
      border-radius: 2px;
      padding: 10px 12px;
    }
  
    .footer {
      display: flex;
      border: 2px solid #EAEDF0;
      border-radius: 2px;
      padding: 10px 12px;
      margin-left: 24px;  
    }
  }  
}
