.tableWrapper {
  margin-right: 42px;
  margin-left: 38px;
}

.buttonObra {
  background: #3547C8;
  color: #fff;
  width: 46px;
  height: 46px;
  padding-right: 7px;
  padding-top: -5px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    background: #3547C8;
    color: #fff;
  }
}