.selectWrap {
    position: relative;
    height: fit-content;
    width: 100%;
}
.optionWrapDefault {
    position: absolute;
    display: flex;
}
.iconSelectBeforeText {
    position: absolute;
    display: flex;
    top: 10%;
    padding: 5px 0;
    right: 15px;
    color: #bdbdbd;
    text-align: center;
}
.textFontSelect {
    font-family: Open Sans;
    color: #828282;
    font-size: 14px;
    text-align: left;
}
.iconSelect {
    position: absolute;
    top: 10%;
    text-align: center;
    right: 21px;
    pointer-events: none;
    transition: ease 0.2s all;

    &.focus {
        transform: rotate(180deg);
    }
}

.iconInput {
    position: absolute;
    left: 12px;
    top: 6px;
    margin-top: -12px;
    z-index: 1;
    background: #44566c;
}
