.archiveConstructionButton {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566C;
  float: left;
  margin: 8px 0 0 28px;
  cursor: pointer;
}

.hintNomenclature {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  float: left;
  margin: 8px 0 0 28px;
  color: #8697A8;
}