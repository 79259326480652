.contentRenderizando {
    width: 100vw;
    height: calc(100vh - 65px);
    background-color: #ededed;

    .infoRenderizando {
        width: 680px;
        display: flex;

        position: relative;
        top: calc(50vh - 150px);
        left: 50%;
        margin-left: -300px;

        .circularProgress {
            width: 70px;
        }

        .infoEtapas {
            margin-top: 2px;
            padding-left: 20px;

            p {
                font-size: 30px;
            }
        }
    }

    .infoError {
        .infoEtapas {
            margin-top: 7px;
            label {
                display: none;
            }
            p {
                color: rgb(226, 87, 76) !important;
            }
        }
    }
}
