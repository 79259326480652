.disciplinasWrapper {
  padding: 27px 21px 17px 40px;
  max-height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
}

.titleTab {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #44566C;
}

.disciplinasList {
  padding-top: 10px;
}

.novaDisciplinaWrapper {
  padding-top: 15px;
}

.formNovaDisciplina {
  display: flex;
  padding-top: 15px;

  > :first-child{
    flex: 1;
    margin-right: 18px;
  }
}

.titleButton {
  padding: 0 20px;
}