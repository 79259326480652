@charset "UTF-8";

body.viewer #content {
  background-color: #f0f8ff;
}

.content-wrapper {
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.simpleViewer {
  width: 100%;
  height: 100%;
  background-color: #f0f8ff;
}

.context-render-loading {
  position: fixed;
  width: 100%;
  top: 170px;
}

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

@keyframes hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(20deg);
  }
}

.loading-square {
  display: flex;
  justify-content: center;
  margin-top: 90px;
}

.loading-square .loading {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
  animation: hue-rotate 10s linear infinite both;
  background-color: transparent;
}

.loading-square .loading__square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 2px;
  background: #07a;
  background-image: linear-gradient(45deg, #32c6cb 40%, #0081FF 60%);
  background-image: -moz-linear-gradient(#32c6cb, #0081FF);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
}

.loading-square .loading__square:nth-of-type(0) {
  animation-delay: 0s;
}

.loading-square .loading__square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
}

.loading-square .loading__square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
}

.loading-square .loading__square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
}

.loading-square .loading__square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
}

.loading-square .loading__square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
}

.loading-square .loading__square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
}

.loading-square .loading__square:nth-of-type(7) {
  animation-delay: -10s;
}

.loading-content h3 {
  text-align: center;
  margin-top: 90px;
}

.loading-content .filename {
  font-weight: 900;
}

.loading-content .progressbar {
  position: relative;
  display: block;
  width: 648px;
  height: 18px;
  border-radius: 14px;
  margin: 40px auto;
  margin-top: 20px;
  margin-bottom: 90px;
  background-color: #fff;
  transition: all 1s ease-in;
}

.loading-content .bar {
  position: absolute;
  display: block;
  width: 0;
  height: 100%;
  border-radius: 14px;
  top: 0;
  left: 0;
  background-color: #4f98e4;
  transition: all 1s ease-in;
}

.loading-content .label {
  transition: all 1s ease-in;
  font-family: "Aldrich", sans-serif;
  position: absolute;
  display: block;
  width: 40px;
  height: 30px;
  line-height: 26px;
  top: 38px;
  left: 0px;
  background: #4c4c4c;
  background: -moz-linear-gradient(top, #4c4c4c 0%, #262626 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4c4c4c), color-stop(100%, #262626));
  background: -webkit-linear-gradient(top, #4c4c4c 0%, #262626 100%);
  background: -o-linear-gradient(top, #4c4c4c 0%, #262626 100%);
  background: -ms-linear-gradient(top, #4c4c4c 0%, #262626 100%);
  background: linear-gradient(to bottom, #4c4c4c 0%, #262626 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#262626', GradientType=0);
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  text-shadow: 0px -1px 0px #000000, 0px 1px 1px #000000;
  filter: dropshadow(color=#000000, offx=0, offy=-1);
}

.loading-content .label span {
  position: absolute;
  display: block;
  width: 12px;
  height: 9px;
  top: -9px;
  left: 14px;
  background: transparent;
}

.loading-content .label span:before {
  position: absolute;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  top: 4px;
  left: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #565656;
  background: -moz-linear-gradient(-45deg, #565656 0%, #4c4c4c 50%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #565656), color-stop(50%, #4c4c4c));
  background: -webkit-linear-gradient(-45deg, #565656 0%, #4c4c4c 50%);
  background: -o-linear-gradient(-45deg, #565656 0%, #4c4c4c 50%);
  background: -ms-linear-gradient(-45deg, #565656 0%, #4c4c4c 50%);
  background: linear-gradient(135deg, #565656 0%, #4c4c4c 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#4c4c4c', GradientType=1);
  -webkit-box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@-webkit-keyframes sparkle {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -64px;
  }
}

@-moz-keyframes sparkle {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -64px;
  }
}

@-o-keyframes sparkle {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -64px;
  }
}

@keyframes sparkle {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -64px;
  }
}

.simpleViewer .adsk-viewing-viewer.light-theme .adsk-button:hover {
  border: 1px solid #0081FF;
}

.simpleViewer .docking-panel #viewables2dList {
  margin-bottom: 30px;
}

.simpleViewer .adsk-toolbar #toolbar-in-viewer-search .adsk-button-icon-search {
  background-image: url(../img/icon-toolbar-search.svg);
  background-size: contain;
  background-position: center;
}

.simpleViewer .adsk-toolbar #toolbar-in-viewer-search .adsk-button-icon-search::before {
  display: none;
}

.simpleViewer .adsk-toolbar #toolbar-in-viewer-search.active .adsk-button-icon-search {
  filter: brightness(6);
}

.simpleViewer .adsk-toolbar .adsk-button.active {
  background-color: #0081FF;
  box-shadow: none;
}

.simpleViewer .adsk-toolbar .adsk-button.active .adsk-button-icon::before {
  color: #fff;
}

.simpleViewer .adsk-toolbar .adsk-button.toolbarNavigatorButton .adsk-button-icon {
  background-size: 20px 24px;
  background-position-y: 2px;
}

.simpleViewer .adsk-toolbar .adsk-button .adsk-button-icon::before {
  color: #0081FF;
}

#maletaGroup .adsk-button-icon {
  background-size: contain;
  background-position: center;
}

#maletaGroup .adsk-button-icon::before {
  display: none;
}

#maletaGroup #toolbarNavigator .adsk-button-icon {
  background-image: url(../img/icon-toolbar-folder.svg);
  background-size: 24px;
}

#maletaGroup #toolbarNavigator #toolbarNavigatorarrow .adsk-button-icon {
  background-image: none;
}

#maletaGroup #toolbarNavigator #toolbarNavigatorarrow .adsk-button-icon::before {
  content: '\25B4';
  padding: 0 5px;
  display: inline;
}

#maletaGroup #navigator2D .adsk-button-icon {
  background-image: url(../img/icon-toolbar-file2d.svg);
  background-size: 24px;
}

#maletaGroup #navigator3D .adsk-button-icon {
  background-image: url(../img/icon-toolbar-file3d.svg);
  background-size: 24px;
}

#maletaGroup #toolbarXLS .adsk-button-icon {
  background-image: url(../img/icon-toolbar-excel.svg);
  background-size: 24px;
}

#pdf-prev-next #pdf-prev .adsk-button-icon {
  background-image: url(../img/icon-toolbar-pdf-prev.svg);
  background-size: 24px;
}

#pdf-prev-next #pdf-next .adsk-button-icon {
  background-image: url(../img/icon-toolbar-pdf-next.svg);
  background-size: 24px;
}

#show-page {
  width: auto !important;
}

.show-page-text {
  font-size: 24px !important;
  color: #0081FF !important;
}

#CollabPanel .userListTable {
  background-color: #fafafa;
}

#CollabPanel .userListTable td {
  color: #333 !important;
}

#CollabPanel .collabBoxOK {
  background-color: #0081FF;
  border: 1px solid #0081FF;
}

#CollabPanel .chatHistory {
  background-color: #fff;
}

#CollabPanel .chatHistory p {
  font-size: 14px;
  padding: 0 10px;
  margin-bottom: 5px;
  margin-top: 10px;
}

#CollabPanel .chatHistory .messageText {
  color: #333;
  padding: 0 10px;
}

#CollabPanel .chatPanel input {
  background-color: #fafafa;
  color: #333;
}

#in-viewer-search .in-viewer-search-bar {
  background-color: #f3f3f3;
  color: #333;
  width: 100%;
  border-radius: 0;
}

#in-viewer-search .queries-container {
  color: #333;
}

#ViewerPropertyPanel .no-properties {
  color: #333;
}

@media screen and (max-width: 980px) {
  .loading-content .progressbar {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .loading-content .progressbar {
    width: 300px;
  }
}


/*# sourceMappingURL=viewer-forge.css.map*/