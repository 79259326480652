.box-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 25px 15px 25px;
  
  div {
    width: 100%;

    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 26px;
    }
  }

  .form-wrapper {
    display: flex;
    width: 100%;

    .field-wrapper {
      display: flex;

      div {
        width: 100%;

        &+div {
          margin-left: 15px;
        }
      }

    }
  }
}
