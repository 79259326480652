.main {
  display: flex;
  flex-direction: column;

  width: 244px;
  height: 282px;

  background: #FFFFFF;
  border: solid 1px #EAEDF0;
  border-radius: 5px;

  margin: 20px 0 0 20px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 10px;
  }

  .midle {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    margin-top: 15px;
    margin-left: 24px;

    span + span {
      margin-top: 10px;
    }
  }

  .footer {
    margin-top: 27px;
    margin-left: 24px;

    span + span {
      margin-left: 5px;
    }
  }
}