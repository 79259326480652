.columnsWrapper {
  display: flex;
  width: 100%;
  height: 420;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: inherit;
}

.columnChildEmpty {
  padding: 22px;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  
  .emptyCard {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;

    .emptyTitle {
      margin-top: 24px;
      font-size: 12px;
      line-height: 16.34px;
      font-weight: 600;
      color: #324150;
      text-align: center;
      margin-bottom: 6px;
    }

    .emptyDescription {
      font-size: 12px;
      line-height: 16.34px;
      font-weight: 400;
      color: #8697A8;
      text-align: center;
      margin-bottom: 32px;
    }

    .button {
      width: 145px;
      height: 38px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .buttonIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .buttonText {
        font-size: 12px;
        line-height: 16.37px;
        font-weight: 600;
        color: #FFFFFF;
        margin-left: 11.67px;
      }
    }
  }
}
