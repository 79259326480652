.drawer {
  border-radius: 8px !important;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding-bottom: 16px;
  border-bottom: 1px solid #EAEDF0;
  width: 100%;

  span {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #324150;
    margin-left: 10px;
    margin-right: 12px;
  }
}

.iconToggleWrap {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .iconChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    color: #BEC2C6 !important;
    transform: rotate(180deg);
    
    &:hover {
      color: #324150 !important;
    }
    
    &.iconActive {
      transform: rotate(360deg);
    }
  }
}

.iconCardWrap {
  width: 48px;
  height: 208px;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  
  .iconCardChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: 6px;
    color: #8697A8 !important;
    
    &.iconActive {
      background-color: #EAEDF0 !important;
      color: #324150 !important;
    }
  }

  .iconCardChildrenActivie {
    color: #BEC2C6 !important;
  }
}

.headerList {
  margin-left: 2px;
  margin-right: -8px;
  height: 32px;
  margin-top: -12px;

  .headerLeftText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #324150;
  } 
  .headerRightText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8697A8;
  }
}

.childrenCollapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -19px;
  background-color: #FFFFFF;
  
  .childrenPanel {
    border: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    background-color: #FFFFFF;
    border: none !important;

    &:hover {
      background-color: #F8FAFB;
    }

    .listPanel {
      background-color: #FFFFFF;

      .childrenList {
        width: 100%;
        display: flex;
        justify-items: start;
        height: 32px;
        padding-left: 14px;
        border: 0 !important;
        cursor: pointer;

        &:hover {
          background-color: #F8FAFB;
        }

        .childrenListWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          width: 100%;
          
          .childrenListWrapperLeft {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            
            .listLeftText {
              text-transform: uppercase;
              margin-left: 10px;
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: #324150;
            }
          }
        }
      }

      .childrenListActive {
        background-color: rgba(34, 204, 226, 0.15);
      }
    }
  }
}
