.container {
  position: absolute;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  width: 295px;
  height: 360px;
  display: flex;
  flex-direction: column;
}

.header {
  background: #3547C8;
  height: 49px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .backButton, .closeButton {
    &:hover {
      i {
        color: #EDEFF2 !important;
      }
    }
  }
}

.titleCurrentFolder {
  margin-left: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  flex: 1;
}

.backButton, .closeButton {
  cursor: pointer;
}

.body {
  flex: 1;
  overflow: auto;
  padding: 10px 0;
  background-color: #fff;
}

.footer {
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  padding: 0 20px;
  background: #F8FAFB;

  .moveFileButton {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #3547C8;
    cursor: pointer;

    &.disabled {
      color: #B3C0CE;
      cursor: default;
      &:hover {
        color: 	#B3C0CE;
      }
    }

    &:hover {
      color: 	#1999AA;
    }
  }
}

.folderItem {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 35px;

  &:hover {
    background-color: #fafafa;
    i {
      color: #3547C8 !important;
    }
  }

  .titleFolderItem {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #B3C0CE;
    padding-left: 25px;
    cursor: pointer;
  }
}

.skeletonTitle {
  width: 100%;
  padding-right: 20px;
  opacity: 0.6;
}

.skeletonFiles {
  padding: 0 10px;
  width: 280px;
  max-width: 280px;
  height: 263px;
  overflow: hidden;
}