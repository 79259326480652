.loginGoogle {
  margin-top: 6px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;

  img {
    padding-right: 11px;
  }

  span {
    margin-top: 1px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #324150;
  }

  &:hover {
    border-radius: 2px;
    background-color: #EAEDF0;
  }
}