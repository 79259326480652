.ActionMenu {
  width: 263px;
  height: 133px;
  background-color: #FFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 500;
  padding-top: 10px;

  .divider {
    border: 0.5px solid #B3C0CE;
    margin: 9px 0px 9px 0px;
  }
   
  div {
    background-color: #FFF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #666666;

    i {
      margin: 2px 10px 5px 10px;
    } 

    &:hover {
      background-color: #F8FAFB;
      cursor: pointer;
      user-select: none;

      i {
        color: rgb(34, 204, 226) !important;
      }
    }
  }
}