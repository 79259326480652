.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    background: #FFFFFF;
  
    .midleHr {
      border: 1px solid #EAEDF0;
    }
  
    .bodyForm {
  
      .bodyDefault {
        display: flex;
        align-items: center;
      }

      .bodyArea {
        display: flex;

        .bodyAreaRight {
          width: 100%;
          margin-left: 60px;
        }
      }
    }
  }
  
  .bodyText {
    min-width: 100px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #8697A8;
  }
}

