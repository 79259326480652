.drawer {
  border-radius: 8px !important;
}

.iconToggleWrap {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .iconChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    color: #BEC2C6 !important;
    transform: rotate(180deg);
    
    &:hover {
      color: #324150 !important;
    }
    
    &.iconActive {
      transform: rotate(360deg);
    }
  }
}

.iconCardWrap {
  width: 48px;
  height: 208px;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  
  .iconCardChildren {
    width: 32px;
    max-width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: 6px;
    color: #8697A8 !important;
    
    &.iconActive {
      background-color: #EAEDF0 !important;
      color: #324150 !important;
    }
  }

  .iconCardChildrenActivie {
    color: #BEC2C6 !important;
  }
}

.drawer {
  border-radius: 8px !important;

  .headerList {
    margin-left: 2px;
    margin-right: -8px;
    height: 32px;
    margin-top: -12px;

    .headerLeftText {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #324150;
    } 
    .headerRightText {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #8697A8;
    }
  }
  
  .childrenCollapse {
    background-color: #F8FAFB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 -19px;
    
    .childrenPanel {
      border: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: red;

      .listPanel {
        background-color: #FFFFFF;
        border: none;

        .childrenList {
          height: 32px;
          padding: 0 14px 0 24px;
          border: 0;
          
          .listLeftText {
            text-align: left;
            width: 50%;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #324150;
          }
          .listRightText {
            text-align: right;
            width: 50%;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #8697A8;
          }
        }
      }
    }
  }

  .iconPanel {
    margin-left: 6px;
    color: #8697A8 !important;
  }
}

.emptyDrawer {
  display: flex;
  min-height: 448px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 19px 0 19px !important;

  p {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    color: #8697A8;
    margin-top: 21px;
  }
}

.childrenContainer {
  padding: 24px 19px 0 19px !important;
}
