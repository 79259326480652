.wrapper {
  padding: 30px 0px 30px 30px;
  height: 260px;
  display: flex;
  .imageWrapper {
    float: left;
    width: 200px;
    height: 220px;
    padding-bottom: 20px;
  }

  .contentWrapper {
    margin-top: 6px;
    margin-left: 10px;
    float: left;
    font-family: "Open Sans", sans-serif;
    h2 {
      font-weight: bold;
      font-size: 24px;
      margin-left: 13px;
      color: #324150;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-left: 13px;
      margin-top: -8px;
      color: #8697a8;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #8697a8;
      margin-left: 13px;
      margin-top: 30px;
    }

    div {
      width: 500px;

      ul {
        columns: 2;
        padding-left: 6px;

        li {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #bec2c6;
          list-style-type: none;
          clear: both;

          i {
            position: relative;
            top: -5px;
            margin-right: 3px;
          }

          i,
          label {
            float: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.button {
  padding: 0 40px !important;
  margin-right: 20px;
}
