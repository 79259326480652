.Wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableWrapper {
    margin-right: 42px;
    margin-left: 38px;
    margin-top: 15px;
}

.footer {
  height: 60px;
  width: 100%;
  position: fixed;
  background: #F8FAFB;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  left: 0;
  right: 0;
  bottom: 0;

  .footerStyle {
    margin-right: 42px;
    padding: 15px;
    margin-left: 250px;
  }

  .titleFooter {
    left: 120px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #B3C0CE;
  }

  .buttonsFooter{
    float: right;
    display: flex;
    margin-right: 0px;
  }

  #btCancelar {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
  
  #btConfirmarBlock {
    margin-left: 20px;
    background: #E0E0E0;
    border: 2px solid #E0E0E0;
    border-radius: 2px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  #btConfirmar {
    margin-left: 20px;
    background: #3547C8;
    border: 2px solid #3547C8;
    border-radius: 2px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  } 

}  

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #44566C;
}


.plotsContainer {
  height: auto;
  overflow: auto;
  .checkColor{
    background: rgba(0, 129, 255, 0.05)!important;
  }
  .checkColorNone{
    background: #EAEDF0 !important;
  }
  .headerItens{
    height: 50px;
    background: #EAEDF0;
    border-radius: 5px;
    padding: 15px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    span{
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #44566C;
      margin-left: 30px;
    }
  }

  .bodyItens{
    display: block;
    max-height: calc(100vh - 393px);
    overflow-y: scroll;

    .itemPlotRequestHidde{
      display: none;
    }

    .fileCheckedColor{
      background-color: rgba(0, 129, 255, 0.05)!important;
    }

    .fileUncheckedColor{
      background-color: #FFFFFF;
    }

    table > tbody  > tr.headerTableItens {
      background: #FFFFFF;
      border: 1px solid #EAEDF0;
      box-sizing: border-box;
      border-radius: 5px;
      height: 51px;
      padding: 15px 30px 15px 30px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      td {
        padding-left: 10px;
      }
      td:first-child{
        padding-left: 30px;
      }
    }

    table > tbody > tr.bodyTableItens {
      width: 97%;
      float: right;
      border: 1px solid #eaedf0;
      border-radius: 5px;
      padding: 11px 30px 11px 20px;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-top: 5px;

      td {
        padding-left: 8px;
      }
      td:first-child{
        padding-left: 0px;
      }
      .FileNameItem{

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

      }
      @media screen and (max-width: 1366px){
        .FileNameItem{
          width: 43vw;
        }  
      }
      @media screen and (min-width: 1367px){
        .FileNameItem{
          width: 44vw;
        } 
      }

    }
  }

  .saveButton {
    margin-top: 35px;
  }
}
