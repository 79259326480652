.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 220px);
  width: 100%;

  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    padding-bottom: 16px;
    border-bottom: 1px solid #EAEDF0;
    width: 100%;

    span {
      text-transform: capitalize;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #324150;
      margin-left: 10px;
      margin-right: 12px;
    }
  }
  
  .mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .title {
      margin-top: 24px;
      text-align: center;
      color: #324150;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  
    .text {
      text-align: center;
      color: #8697A8;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 0;
    }

    .buttonVideo {
      margin-top: 32px;
      width: 147px;
      height: 38px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #BEC2C6;
      }

      i {
        padding-left: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        margin-left: 8px;
        color: #324150;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
}