.header-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  width: 100%;
  height: 50px;
  
  background: #EAEDF0;
  border-radius: 5px;
  margin-top: 20px;

  span {
    color: #44566C;
    font-size: 14px;
    font-weight: 600;
  }

  .first {
    display: flex;
    align-items: center;
    padding-right: 12px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 50px;

  background: #FFFFFF;
  border: solid 1px #EAEDF0;
  border-radius: 5px;

  margin-top: 6px;

  .header {
    display: flex;
    align-items: center;
    padding-right: 12px;
  }
}
