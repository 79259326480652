body {
  overflow: hidden !important;
}

.viewer .page-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  padding: 1% 1% 1% 1%;
  min-height: 62px;
  border-bottom: 1px solid #bdbdbd;

  .wrapperBreadcrumb {
    display: flex;
    align-items: center;

    .drawer {
      margin: 1em;
      color: #0081ff;
      font-weight: 700;
      padding-left: 20px;
      cursor: pointer;
    }

    .isOldFile {
      cursor: no-drop;
      margin: 1em;
      color: #bec2c6;
      font-weight: 500;
      padding-left: 20px;
    }
  }

  .action {
    margin-left: auto;
    padding-right: 10px;
    display: flex;
    .action-item {
      display: flex;
      i {
        margin-right: 10px;
      }
    }
    .action-item-download {
      display: flex;
      i {
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .containerDropwDownaction {
      cursor: pointer;
    }
  }
}
.action-menu-item {
  display: flex;
  align-self: center;
}
.content-viewer {
  width: 100% !important;
  height: calc(100vh - 80px) !important;
}

.content-viewer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90vw;
  max-height: 90vh;
  padding: 10px;
}

.skeleton {
  padding: 20px;
}
