.Toastify__toast-container {
  width: 580px !important;
  height: 44px;
}

.Toastify__toast {
  min-height: 30px !important;
}

.Toastify__toast--success {
  background: #E1FAE6 !important;
  border: 1px solid #B0DEB9 !important;
  border-left: 4px solid #09B66D !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.Toastify__toast--error {
  background: #FDF1F1 !important;
  border: 1px solid #EAB6B8 !important;
  border-left: 4px solid #E2574C !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.Toastify__toast--warning {
  min-height: 88px !important;
  background: #FFFCF7 !important;
  border: 1px solid rgba(253, 191, 94, 0.5) !important;
  box-sizing: border-box !important;
  overflow: auto !important;
  padding: 10px 13px 10px 15px !important;
  border-radius: 5px !important;
}

.Toastify__toast--info {
  background: linear-gradient(0deg, rgba(0, 129, 255, 0.05), rgba(0, 129, 255, 0.05)), #FFFFFF !important;
  border: 1px solid #0081FF80 !important;
  border-left: 4px solid #0081FF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.Toastify__close-button{
  color: #000 !important;
  align-self: center !important;
}

.WarningClass {
  width: 300px !important;
  height: 100px !important;
  margin-left: 271.5px;
}
