.hearderPage > div:first-child {
  padding-left: 2px !important;
}
.breadCrumbAbout {
  margin-left: 5px;
  font-size: 22px !important;
  padding-bottom: 20px;
}
.pageAboutContainer {
  margin-right: 29px !important;
  margin-left: 29px !important;
  margin-bottom: 5px !important;
}

.aboutWrapper {
  padding-bottom: 45px;
  position: absolute;
  li{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color:#8697A8;
  }
  p{
    font-family: Open Sans;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #3547C8;
  }
  .footerAbout {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}
