.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  .firstRow {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .rowWrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 6px;
    background-color: #FFF;
    border: 1px solid #F2F2F2;
    border-radius: 5px;
    padding: 12px 24px;

    .rowWrapperLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      width: 28px;
      height: auto;
    }

    .rowWrapperRight {
      margin-left: 16px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }
}
