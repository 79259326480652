.viewer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 352px) !important;
  height: calc(100% - 52px) !important;
}

.customViewer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100%) !important;
  height: calc(100% - 52px) !important; 
}
