.ant-select .ant-select-selection:focus,
.ant-select .ant-select-selection:active {
  border: 2px solid #3547C8 !important;
  box-shadow: none;
}

.ant-select .ant-select-selection {
  border: 2px solid #EDEFF2;
  border-radius: 2px;
}

.ant-select-selection-selected-value {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697A8;
}

.ant-select-selection__rendered {
  margin-left: 19px !important;
}

.ant-select-dropdown-menu-item .undefined {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  height: 100% !important;
}

.ant-select-selector {
  border-color: #8697a8 !important;
  box-shadow: none !important;
  border-radius: 2px !important;
  min-height: 34px !important;
}

.ant-select-selection-item {
  color: rgba(0, 0, 0, 0.88) !important;
}