.labelTextarea {
  position: relative;
  display: block;
  width: 100%;

  > span {
      position: absolute;
      background-color: white;
      font-size: 10px;
      top: -7px;
      left: 9px;        
      padding: 0 9px;
      color: #BDBDBD;
  }

  > textarea {
      width: 100%;
      border: 2px solid #EDEFF2;
      border-radius: 2px;
      padding: 11px;

      &.valid {
          border: 2px solid #09B66D;
      }
      
      &.invalid {
          border: 2px solid #E2574C;
      }
  }

  > textarea:focus {
      outline-color: #3547C8;
      &::placeholder {
          color: transparent;
      }
  }

  > textarea::placeholder {
      color: #44566C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
  }
  
  i {
      position: absolute;
      left: 12px;
      top: 5px;
  }

  > textarea:not(:focus):placeholder-shown + span {
      color: transparent;
      background: transparent;
      font-size: 14px;
      top: 0px;
      left: 15px;
  }

  > textarea:focus + span {
      font-size: 10px;
      top: -7px;
      left: 9px;
      display: block;
      transition: ease .2s;
  }

  .label {
    pointer-events: none;
  }
}

.error {
    color: #E2574C;
    padding-left: 10px;
    font-weight: 600;
    height: 18px;
}