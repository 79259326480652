.main-wrapper {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #EAEDF0;
  padding: 25px;

  h4 {
    color: #8697A8;
    font-size: 12px;
    font-weight: 600;
  }

  .divider {
    height: 1px;
    background-color: #EAEDF0;
    width: 100%;
    margin: 15px 0;
  }

  .div-text-resource {
    font-size: 12px;
    font-weight: 400;
    color: #8697A8;

    b {
      color: #324150;
      font-weight: 600;
    }
  }

  &+div {
    margin-top: 15px;
  }

  .progress-bar {
    margin-bottom: 15px;
  }
}