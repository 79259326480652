.tag-wrapper {
  position: relative;

  .tag-popularity {
    position: absolute;
    top: 23px;
    right: -2px;
  }
}

.description-box-plan {
  padding: 25px 25px 0 25px;
  height: 490px;
  text-align: start;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #8697A8;
    line-height: 16px;
  }

  .logo-description {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span{
      margin-left: 5px;
      font-size: 24px;
      font-weight: 700;
      color: #44566C;
    }
  }
}

.info-box-plan {
  padding: 25px 25px 0 25px;
  height: 100%;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  padding: 21px 0;

  .price-box-plan {
    padding: 0 25px;
    display: flex;
    justify-content: center;

    :first-child {
      font-size: 12px;
      font-weight: 600;
      color: #BEC2C6;
    }

    .price-box {
      display: flex;
    }
    
    .price-text {
      font-size: 24px;
      font-weight: 600;
      color: #324150;
    }

    .price-custom {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #8697A8;
    }

    .price-text-last {
      align-self: flex-end;
      margin-left: 5px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      color: #BEC2C6;
    }
  }

  .button-box-plan {
    margin: 15px 25px 0 25px;

    .button-plan {
      width: 100%;
      border-radius: 5px;
    }

    .button-custom-plan {
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.content {
  width: 100%;
  height: 1px;
  background-color: #EAEDF0;
  margin-top: 25px;
}
