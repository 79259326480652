.wrapper {
  width: 100%;
  height: 36px;
  display: flex;
  background-color: #FFFFFF;
  margin-bottom: 16px;
  
  .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 6px;
    background-color: #3547C8;

  }

  .disabled {
    cursor: default;
    background-color: #EAEDF0;
    border: 1px solid #BEC2C6;
  }
  
  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 36px;
  
    .icon {
      position: absolute;
      left: 23px;
    }
  
    .input {
      padding-left: 42px;
      margin-left: 8px;
      border: 1px solid #EAEDF0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      outline: none;
      appearance: none;
  
      &:focus {
        border: 1px solid #3547C8;
      }
  
      &::placeholder {
        color: #BEC2C6;
      }
    }

    .notUploadButton {
      margin-left: 0;
    }
  }
}

.menuDropdown {
  width: 153px;
  max-height: 92px;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;

  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    height: 42px;
    cursor: pointer;

    i {
      margin-left: 10px;
    }

    .textItem {
      margin-left: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #324150;
    }

    &:hover {
      background-color: rgba(0, 129, 255, 0.1);
    }
  }
}
