.buttons {
  .buttonCancel {
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  .buttonSubmit {
    border-radius: 5px;
    background-color: #E2574C !important;
    color: #FFFFFF !important;
    margin-right: 4px !important;
    
    &:hover {
      background-color: #E2574C !important;
    }
  }
}

.wrapper {
  padding: 30px 24px 24px 24px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;

  .midleHr {
    margin-top: 12px;
    margin-bottom: 18px;
    border: 1px solid #EAEDF0;
    width: 100%;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #324150;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #324150;

    &+.description {
      margin-top: 18px;
    }
  }
}

.footerHr {
  border: 1px solid #EAEDF0;
  margin: 0 22px;
}

#deleteBtn {
  border-radius: 5px;
  background-color: #E2574C !important;
  color: #FFFFFF !important;
  margin-right: 4px !important;
  
  &:hover {
    background-color: #E2574C !important;
  }
}
