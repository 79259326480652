.selectWrap {
  position: relative;
  height: fit-content;
}
.optionWrapDefault {
  position: absolute;
  display: flex;
}
.iconSelectBeforeText {
  position: absolute;
  display: flex;
  color: #bdbdbd;
  text-align: center;
}
.textFontSelect {
  font-family: Open Sans;
  color: #828282;
  font-size: 14px;
  text-align: left;
}

.iconSelect {
  position: absolute;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 18%;
  text-align: center;
  right: 21px;
  pointer-events: none;
  transition: ease 0.2s all;

  &.focus {
    transform: rotate(180deg);
  }
}

.iconInput {
  position: absolute;
  left: 12px;
  top: 6px;
  z-index: 1;
  background: #44566c;
}

.errorSelect {
  border-radius: 4px;
  border: 2px solid #E2574C;
}

.error {
  color: #E2574C;
  padding-left: 10px;
  font-weight: 600;
  height: 18px;
}
