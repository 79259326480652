.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 17px;

  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding: 0 16px;

    .rowLeft {
      display: flex;
      flex-direction: row;
      align-items: center;

    }
  }
}
