.buttonWrapper {
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  
  &:hover {
    background-color: #EAEDF0;
  }
  
  &.border {
    border: 1px solid #EAEDF0;
    border-radius: 8px;
  }

  &.hideHover {
    &:hover {
      background-color: #FFFFFF;
    }
  }
}