.FloatingFeedbackItem {
  width: 150px;
  height: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;

  .icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5px;
    margin-right: 3px;
  }  

  p {
    color: #09B66D;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 25px;
    margin-left: 10px;
  }
}

.isError {
  p {
    color: #E2574C;
  }
}