.formaInput{
  background: #FFFFFF;
  border: 2px solid #EDEFF2;
  border-radius: 2px
}

.boxDetalhesConta {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;

  .DetalhesContaLeft {
    padding: 0 30px 0 30px;
  }

  .DetalhesContaRight{
    padding: 0 30px 0 0;
    width: 100%;
  }

  .DetalhesConta{
    padding: 0 30px;
    width: 100%;
  }

  .icon{
    float: right;
    user-select: none;
    cursor: pointer;
  }

  .boxData{
    padding: 30px 30px 0 0;

    label.newPassword,label.confirmPassword{
      margin-top: 8px;
    }

  }

  .title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;
  }

  .data{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #44566C;
  }
  
  #titleDetails{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    color: #44566C;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
  }
  
  .contaImg {
    width: 245px;
    height: 174px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border-radius: 5px;

    span {
      height: 135px;
      width: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .editHover {
      position: absolute;
      color: #FFF;
      display: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      justify-content: center;
      align-items: center;
      flex-flow: column;
  
      p {
        margin-bottom: 0px;
      }
    }
  
    &:hover {
      .editHover {
        display: flex;
      }
  
      > span {
        filter: blur(2px);
      }
    }
  
  }
}

.newPasswordFeedback {
  height: auto!important;
  width: 200px !important;
  margin-top: -10px!important;
  margin-bottom: 12px!important;
  div {
    width: 100%; 
  }
}

.confirmNewPasswordFeedback {
  height: 50px !important;
  width: 200px !important;
  margin-top: -10px!important;
  margin-bottom: 12px!important;
  div {
      width: 100%;
  }
}



