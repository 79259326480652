.icon-warning {
  margin-left: -12px;
  margin-top: -11px;
}

.title {
  color: #44566C;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.description {
  color: #44566C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}