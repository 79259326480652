
.collapseListObras-enter {
  opacity: 0;
}
.collapseListObras-enter-active {
  opacity: .3;
  transition: all 200ms ease-in;
}
.collapseListObras-exit {
  opacity: .3;
}
.collapseListObras-exit-active {
  opacity: 0;
  transition: all 300ms ease-out; 
}
