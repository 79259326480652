.avatarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .buttomListUser {
    background: #8697A8 !important;
    color: #FFFFFF !important;
    border: 1px solid #FFFFFF;
    min-width: 24px !important;
    width: 24px;
    height: 24px;
    cursor: pointer !important;
    font-size: 10px;
    line-height: 13.62px;
    font-weight: 600;
    text-align: center;
  }
}

.listUsers {
  display: flex;
  flex-direction: column;
  padding: 1px 3px;
}

.detailUserWrapper {
  display: flex;
  flex-direction: column;
  padding: 2px;

  .detailName {
    margin-top: 12px;
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
    color: #324150;
  }

  .detailEmail {
    margin-top: 8px;
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    color: #8697A8;
  }
}

.detailListUserWrapper {
  display: flex;
  flex-direction: column;
  padding: 2px;
  max-height: 280px;

  .detailTitle {
    font-size: 10px;
    line-height: 13.62px;
    font-weight: 600;
    color: #BEC2C6;
  }
  
  .detailContent {
    margin-top: 9px;
    overflow-y: auto;

    .detailListUserRow {
      display: flex;
      align-items: center;
  
      &+.detailListUserRow {
        margin-top: 6px;
      }
  
      .detailRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 6px;
  
        .detailName {
          font-size: 12px;
          line-height: 16.34px;
          font-weight: 600;
          color: #324150;
        }
      
        .detailEmail {
          font-size: 10px;
          line-height: 13.62px;
          font-weight: 400;
          color: #BEC2C6;
        }
      }
    }
  }
}