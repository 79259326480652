.ant-menu-item {
  padding-bottom: 8px !important;
  padding-top: 4px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-bottom: 1px solid #DADADA;

  height: 50px !important;
}

.ant-table-selection-column {
  width: 40px;
}

.ant-table-row-expand-icon-cell {
  display: none;
}

.ant-table-expand-icon-col {
  display: none;
}

.ant-menu-item:active {
  background-color: #F8FAFB !important;
  color: #666 !important;
}

.ant-menu-item:hover {
  background-color: #F8FAFB !important;
}

.ant-menu-submenu-title::selection {
  background: #666 !important;
}

.ant-menu .ant-menu-item:last-child {
  margin-bottom: 4px;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #F8FAFB !important;
}

.ant-menu-submenu-title:hover {
  color: #666 !important;
}

.ant-menu-submenu-arrow:hover {
  background: #666 !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: #666 !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #666 !important;
}

.ant-table-filter-trigger {
  cursor: none;
  background-color: transparent !important;
}