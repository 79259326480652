.wrapper {

  .header {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAEDF0;
    flex-wrap: wrap;

    .customInput {
      margin: 0 12px;
      padding-left: 48px;

    }

    .customIcon {
      left: 34px
    }

    .mainHeader {
      width: 100%;
      min-height: 44px;
      max-height: 132px;
      overflow-y: scroll;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 28px;
      
      .rowHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #BEC2C6;
        
        .rowHeaderDiscipline {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        &:last-child {
          color: #324150;
        }
      }

      .iconBreadCrumb {
        transform: rotate(180deg);
      }
    }
  }

  .main {
    width: 100%;
    height: 365px;
    max-height: 365px;
    overflow-y: scroll;

    .rowDisciplinesList {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      padding-left: 33px;
      border-radius: 6px;
      width: 100%;
      height: 48px;
  
      &+.rowDisciplinesList {
        margin-top: 4px;
      }
  
      &:hover {
        background-color: #EAEDF0;
      }
  
      .leftRowDisciplinesList {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        .disciplineListText {
          margin: 0;
          margin-left: 12px;
          color: #44566C;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }

        .disciplineListTextOpaco {
          color: #BEC2C6;
        }
      }
  
      .iconExpanded {
        transform: rotate(180deg);
      }
    }

    .disabledRowDisciplinesList {
      cursor: not-allowed;
    }

    .selectedFileList {
      background: rgba(34, 204, 226, 0.15);
      
      &:hover {
        background: rgba(34, 204, 226, 0.15);
      }
    }

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 174px !important;
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #EAEDF0;
    }
  }
}

.buttons {
  .buttonCancel {
    cursor: pointer !important;
    width: 123px;
    border-radius: 5px;
  }
  
  .buttonConfirm {
    cursor: pointer;
    width: 140px;
    border-radius: 5px;
  
    &:disabled {
      background-color: #EAEDF0 !important;
      color: #BEC2C6 !important;
      border: 1px solid #BEC2C6 !important;
    }
  }

  .buttonConfirmMaleta {
    width: 190px;
  }
}
