.title-box-plan {
  padding-bottom: 15px;
  border-bottom: 1px solid #EAEDF0;

  h4 {
    font-size: 12px;
    font-weight: 600;
    color: #8697A8;
  }
}

.description-box-plan {
  padding: 25px 0;
  border-bottom: 1px solid #EAEDF0;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #8697A8;
  }

  .logo-description {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span{
      margin-left: 5px;
      font-size: 24px;
      font-weight: 700;
      color: #44566C;
    }
  }
}

.info-box-plan {
  padding: 25px 0;
  border-bottom: 1px solid #EAEDF0;
}

.price-box-plan {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;

  :first-child {
    font-size: 12px;
    font-weight: 600;
    color: #BEC2C6;
  }

  .price-box {
    display: flex;
    flex-direction: column;
  }
  
  .price-text {
    font-size: 24px;
    font-weight: 600;
    color: #324150;
  }

  .price-text-last {
    align-self: flex-end;
    font-size: 12px;
    font-weight: 400;
    color: #BEC2C6;
  }
}

.button-box-plan {
  margin-top: 15px;
}

.button-box-downgrade {
  margin-top: 25px;
}
