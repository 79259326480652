.main-wrapper {
  padding: 30px 30px 40px 40px;

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;

    &+p {
      margin-top: 30px;
    }
  }
  
}

button.cancel-buttons {
  border-radius: 5px;
  padding: 0 32px;
  
  &:last-child {
    margin-right: 12px;
  }
}