.extensoesContainer {
  padding: 27px 36px 23px 36px;
  height: auto;
  overflow: auto;

  .extensionGroup {
    background-color: #eaedf0;
    padding: 10px 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  .extensionItem {
    label {
      border: 1px solid #eaedf0;
      background-color: #fff;
      border-radius: 5px;
      padding: 16px 30px;
      margin-top: 8px;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .saveButton {
    margin-top: 35px;
  }
}

.textWrap {
  padding: 40px;

  div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #44566C;
  }

  .margin {
    margin-bottom: 25px;
  }
}