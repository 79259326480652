.textWrap {
  padding: 30px;

  div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #44566C;
  }

  .margin {
    margin-bottom: 25px;
  }
}