.dadosEmpresaImg {
  width: 225px;
  height: 135px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 5px;
  
  span {
    height: 135px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  img {
    width: 100%;
    height: 135px;
  }

}

.dadosEmpresaName {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding-left: 20px;

  strong {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #44566C;

    span {
      color: #FFF;
    }
  }
}

.dadosEmpresaLeft{
  margin: 20px 10px 0 20px;

  table {
    width: 100%!important;
  }

  table > thead > tr > th:first-child {
    padding-left: 20px!important;
  }
  
  table > thead > tr > th {
    padding-right: 20px!important;
    cursor: pointer;
  }

  @media screen and (max-width: 1589px) {
    table {
      width: 346px!important;
    }
  }
  @media screen and (min-width: 1590px) {
    table {
      width: 450px!important;
    }
  } 
}

.dadosEmpresaRight{
  margin: 20px 10px 0 10px;

  table {
    width: 100%!important;
  }

  table > thead > tr > th:first-child {
    padding-left: 20px!important;
  }
  
  table > thead > tr > th  {
    padding-right: 20px!important;
    cursor: pointer;
  }

  @media screen and (max-width: 1589px) {
    table {
      width: 450px!important;
    }
  }

  @media screen and (min-width: 1590px) {
    table {
      width: 554px!important;
    }
  }
}

.icon {
  float: right!important;
}

.valueItem {
  text-align: right!important;
  font-family: Open Sans!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 12px!important;
  line-height: 16px!important;
  text-align: right!important;
  color: #44566C!important;

  span {
    margin-right: 20px!important;
  }
  span.valueObrigatorio {
    color: #E2574C!important;
  }

  .value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.titleItem {
  font-family: Open Sans!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 12px!important;
  line-height: 16px!important;
  align-items: center!important;
  color: #8697A8!important;

  span {
    margin-left: 20px!important;
  }
  span.titleObrigatorio {
    color: #E2574C!important;
  }
}
