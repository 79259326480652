.usuariosConvidadosWrapper {
  padding: 27px 31px 20px 34px;
  max-height:  312px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.convertionToPremiumModal{
  text-align: center;
  position: absolute;
  background: rgba($color: #f8fafb, $alpha: 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: -34px;
  z-index: 1;

  .primaryTextConvertion{
    font-weight: 700;
    font-size: 14px;
    color: #3547C8;
    line-height: 21px;
    margin: 0px;
  }

  .secondaryTextConvertion{
    font-weight: 700;
    color: #8697A8;
    font-size: 12px;
    line-height: 18px;
    margin: 0px;
  }
}

.newUsersWrapper {
  display: flex;
  justify-content: space-between;

  .inputEmail {
    margin-bottom: 0;
    margin-right: 15px;
    width: 100%;

    input {
      padding-left: 24px;
    }
  }
}

.selectRoleWrapper {
  display: flex;
  align-items: center;
}

.selectRole {
  width: fit-content !important;
  margin-right: 13px !important;
  border-width: 2px !important;
  color: #8697a8 !important;

  > div:focus {
    outline-color: #3547C8 !important;
    color: #8697a8 !important;
  }

  > div {
    width: 160px !important;
  }

  > div:first-child {
    height: 35px !important;
    border: 2px solid #edeff2 !important;
    border-radius: 2px !important;
    color: #8697a8 !important;

    div {
      color: #8697a8 !important;
    }
  }
}

.buttonNewUser {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
}

.permissionsHintWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 22px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #bdbdbd;
  cursor: default;
}

.titleSharedUserList {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566c;
  padding-bottom: 19px;
}

.titleSharedUserListFreemium {
  font-weight: 600;
  font-size: 12px;
  color: #BEC2C6;
  margin-top: -19px;
  margin-bottom: 16px;
}

.sharedUserItem {
  display: flex;
  margin-top: 6px;
  margin-right: 0;
  width: 100%;
}

.hintText {
  padding: 23px;
  width: 323px;
}

.invitedUserWrapper {
  padding-right: 19px;
  padding-left: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 13px;
  }
}

.invitedUserName,
.sharedUserName {
  padding-left: 26px;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697a8;
}

.selectionsWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sharedRoleName {
  flex: 1 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #8697a8;
  border: 2px solid #edeff2 !important;
  height: 35px;
  max-width: 160px;
  margin-right: 10px;
  text-align: center;
  padding-top: 6px;
  border-radius: 2px;
}

.shareButton {
  width: 147px;
  font-size: 12px !important;
  justify-content: center;
  align-items: center;
  display: flex !important;

  &.shared {
    border: 2px solid #09b66d;
    color: #09b66d !important;
    &:hover,
    &:focus {
      border-color: #079e5f;
    }
  }

  &:hover {
    background: #edeff2 !important;
  }

  > span {
    display: flex !important;
    align-items: center;
  }
}

.waitingButton {
  height: 32px;
  margin-left: 13px;

  display: flex;
  .waitingTitle {
    padding: 6px 18px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #fdbf5e;
    user-select: none;
    cursor: default;

    border: 2px solid #fdbf5e;
    border-radius: 2px;
  }

  .buttonRemoveUser {
    width: 34px;
    margin: 0;
    border-left: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-left: none !important;
    }
    &:active {
      border-left: none !important;
    }
  }
}

.iconInfo {
  transition: color ease 0.3s;
  &:hover {
    color: #3547C8 !important;
  }
}
.wrapper {
  
  padding-left: 29px;
  padding-right: 29px;
  height: 400px !important;
  position: fixed;

  .imageWrapper {
    float: left;
    width: 240px;
    height: 260px;
  }

  .contentWrapper {
    margin-left: 10px;
    float: left;
    font-family: "Open Sans", sans-serif;

    .avisoLimiteObras {
      color: #3547C8;
      border: 2px solid #3547C8;
      border-radius: 25px;
      padding: 7px 25px;
      font-weight: 600;
      font-size: 12px;
    }

    h2 {
      font-weight: bold;
      font-size: 22px;
      margin-left: 13px;
      margin-top: 30px;
      color: #324150;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-left: 13px;
      margin-top: -8px;
      color: #8697a8;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #8697a8;
      margin-left: 13px;
      margin-top: 30px;
    }

    div {
      width: 500px;

      ul {
        columns: 2;
        padding-left: 6px;

        li {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #bec2c6;
          list-style-type: none;
          clear: both;

          i {
            position: relative;
            top: -5px;
            margin-right: 3px;
          }

          i,
          label {
            float: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.button {
  padding: 0 40px !important;
  margin: 17px;
}

.TopbarInput {
  background-color: #fff;
  border: 1px solid #EAEDF0;
  border-radius: 2px;
  display: flex;
  width: 100%;
  height: 42px;
  margin-top: -12px;
  
  &:focus-within {
    border: 1px solid #3547C8;
  }
  
  .TopbarInputSearch {
    margin-left: 6px;
    margin-right: 36px;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 22px;

  .textBottomHead {
    color: #324150;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  .textBottomFooter {
    color: #8697A8;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 6px;
  }
}

.usuariosConvidadosWrapperNoOver {
  overflow-y: hidden;
}
