.Grid {
  display: flex;
  width: 100%;
  padding: 25px 30px;

  .grid-left {
    width: 100%;

    .box-external-buttons {
      margin-top: 15px;

      .external-buttons-left {
        cursor: pointer;
        width: 105px;
        border-radius: 5px;
      }
    
      .external-buttons-right {
        width: 288px;
        border-radius: 5px;
        margin-left: 10px;
      }
    }
  }

  .grid-right {
    max-width: 350px;
    margin-left: 15px;
  }
}