ul > .ant-tree > .ant-tree-icon-hide {
  width: auto !important;
}

.ant-tree-node-content-wrapper ,.ant-tree-node-content-wrapper-open {
  width: 90%;
}

.ant-tree-treenode {
  width: 90%;
}

.ant-tree-node-selected {
  background-color: transparent !important;
}

.ant-tree-node-content-wrapper:hover {
  background-color: #F8FAFB !important;
}

.ant-tree-switcher, .ant-tree-switcher_open {
  color: #BEC2C6 !important;
}

.ant-drawer-wrapper-body::-webkit-scrollbar {
  width: 5px !important;
  height: 174px !important;
  background-color: #FFFFFF;
}

.ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #EAEDF0;  
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  box-shadow: none !important;
}