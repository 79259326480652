.breadcrumb {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px !important;
  color: #8697a8 !important;
  padding-left: 38px !important;

  [class*="ant-breadcrumb-link"] {
    cursor: pointer;
  }
}

.custom {
  padding-left: 16px !important;
}

@media print {
  .breadcrumb {
    display: none;
  }
}