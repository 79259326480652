.wrapper {
  padding: 40px;

  p {
    margin: 16px 0;
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    .selectRole {
      width: auto !important;
      max-width: 280px !important;
    
      > div:focus {
        outline-color: #3547C8 !important;
      }
    
      > div {
        width: 170px;
        height: 40px;
        border: 2px solid #edeff2 !important;
        border-radius: 2px;
      }
    }
  }
}

.danger {
  padding: 4px 8px;
  border: 1px solid red;
  border-radius: 5px;

  & > p {
    text-align: center;
    margin: 0;
    color: red;
    font-weight: 700;
  }
}
