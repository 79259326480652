.labelInput {
    position: relative;
    display: block;

    > span {
        position: absolute;
        background-color: white;
        font-size: 10px;
        top: -7px;
        left: 9px;        
        padding: 0 9px;
        color: #BDBDBD;
    }

    > input {
        height: 35px;
        width: 100%;
        border: 2px solid #EDEFF2;
        border-radius: 2px;
        padding-left: 10px;

        &.valid {
            border: 2px solid #09B66D;
        }
        
        &.error {
            border: 2px solid #E2574C;
        }
    }

    > input:focus {
        outline-color: #3547C8;
        &::placeholder {
            color: transparent;
        }
    }

    > input::placeholder {
        color: #8697A8;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
    
    i {
        position: absolute;
        left: 12px;
        top: 5px;
    }

    > input:not(:focus):placeholder-shown + span {
        color: transparent;
        background: transparent;
        font-size: 14px;
        top: 0px;
        left: 15px;
    }

    > input:focus + span {
        font-size: 10px;
        top: -7px;
        left: 9px;
        display: block;
        transition: ease .2s;
    }
}

.paddingIcon {
    padding-left: 42px !important;
}