.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  .mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .center {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .bottom {

    }
  }
}
