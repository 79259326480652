.Modal {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  width: 350px;
  height: 390px;
  position: absolute;
  z-index: 2;
  right: 25px;
  top: 155px;
}

.Wrapper {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  .header {
    background-color: #3547C8;
    border-radius: 8px 8px 0px 0px;
    
    .imageBox {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 40px 32px 40px;

      img {
        max-width: 100%;
      }
    }

    .iconBox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;
      padding-right: 10px;

      i {
        cursor: pointer;
      }
    }
  }

  .content {
    background-color: #FFF;
    padding: 20px 20px 15px 20px;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #324150;
    }

    .description {
      font-size: 10px;
      line-height: 16px;
      color: #8697A8;
    }

    .tag {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 10.89px;
      margin-bottom: 15px;
      border-radius: 15px;
    }
  }
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #FFF;
  padding-right: 20px;
  padding-bottom: 20px;

  .link {
    padding: 10px 25px;
    margin-right: 5px;

    a {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: #324150;
      text-decoration: none;
    }
  }
}