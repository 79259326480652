.listFilesWrapper {

  .headerListFiles {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #EAEDF0;
    margin-top: -8px;
    margin-bottom: 16px;
    
    .headerListFilesContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;

      .iconBack {
        cursor: pointer;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 4px;
        border: none;
        margin-right: 8px;

        &:hover {
          background-color: #EAEDF0;
        }
      }

      .disciplineHeader {
        margin-left: 12px;
        color: #324150;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .contentListFiles {
    height: calc(100vh - 255px);
    max-height: calc(100vh - 255px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 174px !important;
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #EAEDF0;
    }
      
    .rowFile {
      width: 100%;
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &+.rowFile {
        margin-top: 8px;
      }

      &:hover {
        background-color: #F8FAFB;
      }

      .leftRowFile {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 46px;

        .headerRowFile {
          margin-top: 6px;

          .disciplineListText {
            color: #324150;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
          }
        }
        
        .footerRowFile {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: -4px;
          margin-bottom: 4px;

          .disciplineListIcon {
            margin-left: -5px;
            margin-right: -4px;
          }

          .disciplineListText {
            color: #BEC2C6;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            margin-left: 4px;
            padding-left: 4px;
            border-left: 1px solid #BEC2C6;
          }
        }
      }
    }

    .rowTree {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 307px;
      padding: 0 !important;
      overflow-x: hidden;
    }
  }
}

.disciplinesWrapper {
  max-height: calc(100vh - 190px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 174px !important;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #EAEDF0;
  }

  .rowDisciplinesList {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 6px;
    width: 100%;
    height: 48px;

    &+.rowDisciplinesList {
      margin-top: 4px;
    }

    &:hover {
      background-color: #EAEDF0;
    }

    .leftRowDisciplinesList {
      display: flex;
      flex-direction: row;
      align-items: center;

      .disciplineListText {
        margin: 0;
        margin-left: 12px;
        color: #44566C;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .iconExpanded {
      transform: rotate(180deg);
    }
  }
}

.mainWrapper {
  max-height: 200px;
  overflow-y: scroll;
}

.actions {
  rotate: calc(90deg);
  margin-left: 4px;
  cursor: pointer;
}

.MenuDropdown {
  //position: absolute;
  background: #FFFFFF;
  width: 219px;
  height: 100px;
  padding: 4px 0;
  // margin-left: -20px;
  // margin-top: -92px;
  
  .menuItem {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #324150;
    cursor: pointer;

    &+.menuItem {
      margin-top: 4px;
    }

    &:hover {
      background: rgba(0, 129, 255, 0.1);
    }

    .unbind {
      margin-right: 8px;
    }
  }
}

.MenuDropdownHeight {
  height: 52px;
}
