.MenuDropdown {
  width: 150px;

  .menuItem {
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #324150;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 129, 255, 0.1);
    }
  }

  .menuItemRemove:hover {
    background-color: #E2574C1F;

    i {
      color: #E2574C !important;
    }
  }

  i {
    width: 24px;
    margin-right: 10px;
  }

  .menuItemCustomRemove:hover {
    background-color: #0081FF1A;

    i {
      color: #8697A8 !important;
    }
  }
}
