.tooltipRender{
  padding: 10px;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #44566c;
    margin-bottom: 12px;
  }

  .value {
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    color: #8697a8;
  }

  .percent{
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    color: #8697a8;
    margin-top: 7px;
  }

  .loading {
    margin-top: -1px!important;
    margin-right: 8px!important;
    height: 12px;
    width: 12px !important;
  }

}

.fileRender{
  display: flex;
  .loading {
    margin-top: 1px!important;
    margin-right: 8px!important;
    height: 12px;
    width: 12px !important;
  }
}

.iconError {
  // margin: -2px;
  margin-left: -6px;
}
