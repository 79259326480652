.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  
  .search {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8FAFB;
    border-radius: 22px;
    margin-bottom: 12px;
  }

  .firstChild {
    color: #324150;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .lastChild {
    color: #8697A8;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
