.contentWrapper {
    padding: 26px 32px;
    height: 100%;
    width: 100%;

    label {
        padding-bottom: 17px;
    }
}

.title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #44566C;

    padding-bottom: 22px;
}

.link {
    color: #3547C8;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    &:hover {
        text-decoration: underline;
    }
}

.buttonWrapper {
    padding-top: 8px;
}

.backLoginWrapper {
    padding-top: 25px;
    text-align: center;
}

.passwordFeedback {
    position: absolute;
    top: 135px;
    right: -120px;
}

.confirmPasswordFeedback {
    position: absolute;
    height: 50px !important;
    width: 190px !important;
    top: 185px;
    right: -160px;
    div {
        width: 100%;
    }
}

.powered-by {
    color: #BEC2C6;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    text-align: center;
    margin-top: 30px;

    span {
        font-weight: 700;
    }
}