.hearderPage > div:first-child {
  padding-left: 2px !important;
}
.breadCrumb {
  margin-left: 5px;
  font-size: 22px !important;
  padding-bottom: 20px;
}
.pageContainer {
  margin-right: 29px;
  margin-left: 29px;
}

.infoPapelContainer {
  margin-right: 29px;
  margin-left: 29px;
  margin-bottom: 29px;
  height: 150px;
  left: 245px;
  top: 183px;
  border-radius: 10px;
  background: #0081ff;
  display: flex;
  .containerPapeisPermissoes {
    padding-top: 25px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .text {
    margin-top: 20px;
  }

  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
  }

  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
  }
}
.headerPapelContainer {
  display: flex;
  margin-right: 29px !important;
  margin-left: 29px !important;
  margin-bottom: 5px !important;
  text-align: center !important;
  b {
    font-family: Open Sans;
    font-size: 14px;
    color: #324150;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
  }
}
.containerCollapse {
  padding-bottom: 10px;
  .panelContainer {
    display: flex;
    align-items: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #324150;
  }
  .panelPapeisPermissoes {
    flex-direction: auto;
  }
  .indexGray {
    height: 50px;
    background-color: #f8fafb;
    border-bottom: 1px solid #eaedf0;
    margin: 0px !important;
  }
  .indexGray:first-child {
    height: 50px;
    background-color: #f8fafb;
    border-top: 1px solid #eaedf0;
    border-bottom: 1px solid #eaedf0;
    margin: 0px !important;
  }
  .indexWhite {
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #eaedf0;
    margin: 0px !important;
  }
}
.iconTablePapeisPermissoes {
  color: #3547C8 !important;
  display: flex;
  padding: 23px 30px;
}
.titleTable {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #324150;
}

.rowTitleContainer {
  .title {
    margin: 15px 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #324150 !important;
    white-space: nowrap;
  }
}
.rowDataContainer {
  align-items: center;
  text-align: center !important;
  .rowDataMarked {
    margin-top: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #3547C8 !important;
    display: inline-block;
    max-width: 121px;
    height: 49;
  }
  .rowDataUnMarked {
    margin-top: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    color: #bec2c6 !important;
    max-width: 120px;
    height: 49;
  }

  .rowIconMarked {
    margin-top: 19px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #3547C8 !important;
    display: inline-block;
    max-width: 120px;
    height: 49;
  }
  .rowIconUnMarked {
    margin-top: 19px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    color: #bec2c6 !important;
    max-width: 120px;
    height: 49;
  }
}
