.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F8FAFB;

  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding-right: 16px;
    padding-left: 33px;
    border-bottom: 1px solid #EAEDF0;

    .rowLeft {
      display: flex;
      flex-direction: row;
      align-items: center;

    }
  }
}
