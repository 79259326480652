.Grid {
  display: flex;
  width: 100%;
  padding: 25px 30px;

  .grid-left {
    width: 100%;

    .row-wrapper {
      display: flex;
      justify-content: start;
      align-items: center;

      .image-row {
        margin-right: 10px;
        margin-left: -8px;
      }
  
      .text-row {
        color: #44566C;
        font-size: 12px;
        font-weight: 600;
      }
  
      .text-footer-row {
        color: #BEC2C6;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  .grid-right {
    max-width: 350px;
    margin-left: 15px;
    
    button{
      cursor: pointer;
      border-radius: 5px;
    }

    .confirm-button-box-plan {
      border: 1px solid #BEC2C6;
    }
    
    .cancel-button-box-plan {
      margin-left: 10px;
    }
  } 
}
