.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    padding: 0 24px;
    background: #FFFFFF;
  
    .midleHr {
      border: 1px solid #EAEDF0;
    }
  
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
  
      .titleHeader {
        display: flex;
        flex-direction: row;
        align-items: center;

        .titleHeaderBar {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.34px;
          color: #EAEDF0;
          margin-left: 6px;
        }
      }
      
      .multipleIconHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
  
      .iconHeader {
        padding: 4px;
        background-color: #EAEDF0;
        border: 1px solid #BEC2C6;
        border-radius: 5px;
        margin-left: 6px;
        cursor: not-allowed;
      }
    }
  }
}

