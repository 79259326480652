.selectWrap {
  position: relative;
  height: fit-content;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 2px;

  .iconClear {
    position: absolute;
    height: auto;
    width: auto;
    top: 0;
    right: 0;
    margin-top: 4px;
    margin-right: 4px;
  
    z-index: 30;
    cursor: pointer;
  }
}
.iconSelectBeforeText {
  position: absolute;
  display: flex;
  top: 10%;
  padding: 5px 0;
  right: 15px;
  color: #bdbdbd;
  text-align: center;
}
.iconSelect {
  position: absolute;
  top: 10%;
  text-align: center;
  right: 21px;
  pointer-events: none;
  transition: ease 0.2s all;
  color: #324150;

  &.focus {
      transform: rotate(180deg);
  }
}
.option {
  height: 48px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #0081FF1A;
  }
}

.SelectText {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: #324150;
}

.iconInput {
  position: absolute;
  left: 12px;
  top: 6px;
  margin-top: -12px;
  z-index: 1;
  background: #44566c;
}

.noActiveWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  // min-height: 32px;
  max-height: 32px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 3px;
  padding: 8px 10px;
  overflow: hidden;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
  
  &:hover {
    background-color: #EAEDF0;
  }

  .noActiveText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: #324150;
  }
  .noActiveTextSoft {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #BEC2C6;
  }
}

.nPCursor {
  cursor: default;
}

.nPCursorTool {
  cursor: not-allowed;
}
