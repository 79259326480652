body > div > div > div.ant-select-dropdown {
  display: block !important;
}

.headerBreadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 43px;
}

.tableContainer {
  margin: 0 38px;

  .space {
    height: 66px;
  }

  .subTitle {
    margin-top: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #44566c;
  }

  .boxSolicitaPlot {
    margin-top: 10px;
    .ant-radio-checked .ant-radio-inner::after {
      border-spacing: 0 0 !important;
    }
    .inputs {
      input {
        height: 51px !important;
        background: #ffffff !important;
        border: 1px solid #b3c0ce !important;
        box-sizing: border-box !important;
        border-radius: 3px !important;

        font-family: Open Sans !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        display: flex !important;
        align-items: center !important;
        color: #44566c !important;
      }

      input[type="text"]:disabled {
        background: #ebeef0 !important;
      }
    }

    .rowBorder {
      border-bottom: 1px solid #b3c0ce;
    }

    .row {
      padding: 25px 20px 7px 20px;
      textarea {
        height: 102px !important;
        background: #ffffff !important;
        border: 1px solid #b3c0ce !important;
        box-sizing: border-box !important;
        border-radius: 3px !important;

        font-family: Open Sans !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        display: flex !important;
        align-items: center !important;
        color: #44566c !important;
      }

      textarea::placeholder {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #bdbdbd;
      }
    }

    .rowCopias {
      padding: 25px 20px 25px 20px;
      border-bottom: 1px solid #b3c0ce;
      .titleCopias {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #44566c;
        opacity: 0.6;
      }
      .qtCopias {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #44566c;
      }
    }

    .rowEndereco {
      padding: 25px 20px 25px 20px;
      border-bottom: 1px solid #b3c0ce;

      .radioAddress {
        display: flex;
        .addresOption {
          margin-top: -3px !important;
          font-family: Open Sans !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          display: flex !important;
          align-items: center !important;
          color: #8697a8 !important;
        }
      }
      cursor: pointer;
    }

    .rowDefaultEmail {
      padding: 25px 20px 25px 20px;
      border-bottom: 1px solid #b3c0ce;
      .defaultEmail {
        margin-left: 20px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #8697a8;
      }

      .inputEmails {
        > div {
          height: auto !important;
          min-height: 51px !important;
          font-family: Open Sans !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          display: flex !important;
          align-items: center !important;
          color: #44566c !important;
          padding: 8px !important;
          border: 1px solid #b3c0ce !important;
        }

        > div > div > div > span {
          border-radius: 100px;
          margin-left: 0px;
          background: #eaedf0;
        }

        > div > div > ul > li {
          border-radius: 100px;
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }
}

.editTable {
  thead > tr > th {
    padding-left: 28px !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
  }
}

.listTable {
  .file-list-row {
    &:hover {
      .iconEditInList {
        display: flex;
      }
    }
  }

  .fileRender {
    display: flex;
    .loading {
      margin-top: 1px!important;
      margin-right: 8px!important;
      height: 12px;
      width: 12px !important;
    }
  }
}

.nameCollumn {
  display: flex;
  align-items: center;
  user-select: none;
  padding-right: 10px;

  .name:hover {
    cursor: pointer;
    text-decoration-line: underline;
    color: #3547C8;
  }

  .name {
    padding-left: 24px;
    color: #44566c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .dot {
      padding-left: 20px !important;
    }

    &.hasInconsistencia {
      color: #e2574c;
    }
  }

  .nameActive {
    color: #3547C8;
  }

  .iconEdit {
    margin-left: 10px;
    cursor: pointer;
  }

  .iconEditInList {
    display: none;
    margin-left: 10px;
    cursor: pointer;
  }

  .iconEditInListActive {
    display: flex;
  }
}

.menuStatusChange {
  width: 119px;
  padding: 15px 0;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: auto;

  .options {
    padding: 10px 0 10px 15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666666;
    cursor: pointer;

    &:hover {
      background: #f8fafb;
    }
  }
}

.formWrapper {
  display: flex;
  padding: 20px 44px 29px 0;
  width: 100%;
  overflow-x: auto;
}
.formWrapperListEdit {
  padding: 20px 44px 29px 24px;
}

.fieldsWrapper {
  display: flex;

  span {
    padding-right: 15px;
    &:last-child {
      padding-right: 0;
    }
    label {
      min-width: 185px;
      input {
        height: 42px;
      }
    }
  }
}

.simpleFileNameWrapper {
  width: 600px;
  label {
    min-width: 185px;
    input {
      height: 42px;
    }
  }
}

.descriptionWrapper {
  margin-top: 7px;
  width: 100%;
  label {
    min-width: 185px;
    input {
      height: 42px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  button {
    width: 145px;
    margin-left: 15px;
  }
}

.editColumnWrapper {
  padding-left: 25px;
}

.nameColumnEdit {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 10px 0;

  .name {
    padding-left: 24px;
    color: #44566c;

    &.hasInconsistencia {
      color: #e2574c;
    }
  }

  .iconEdit {
    display: none;
  }

  &:hover,
  &.active {
    .iconEdit {
      padding-left: 10px;
      display: flex;
      color: #3547C8 !important;
    }

    span {
      color: #3547C8;
    }
  }

  &.active {
    .iconFile {
      color: #3547C8 !important;
    }
  }
}

.emptyListWrap {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #44566c;
    width: 485px;
    margin-top: 25px;
  }
}

.descriptionTooltip {
  padding: 10px 17px 20px 17px;
  min-width: 400px;
  .iconWrap {
    padding-bottom: 15px;
  }

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #666666;
}

.dragInfoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  width: 100%;

  .folderDescriptionWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 417px;
    height: 81px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    margin-top: 57px;
    padding-top: 12px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #8697a8;

    .folderName {
      font-size: 18px;
      padding-top: 8px;
    }
  }
}

.dragErrorInfoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  width: 100%;

  .folderDescriptionWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 417px;
    height: 81px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    margin-top: 57px;
    padding-top: 12px;

    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #E2574C;

    .folderName {
      font-size: 18px;
      padding-top: 8px;
    }
  }
}
.versionSelect {
  display: flex;
  cursor: pointer;

  .revision {
    font-size: 14px;
  }

  &:hover {
    .versionSelectIcon {
      color: rgb(117, 125, 133) !important;
    }

    .revision {
      color: #3547C8;
    }
  }
}

.loadingVersionDropdownWrapper {
  .loadingVersionDropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;
    cursor: default;

    span {
      font-size: 10px;
      line-height: 13px;
      font-weight: 400;
      color: #BEC2C6;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
.tooltipRender{
  padding: 10px;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #44566c;
    margin-bottom: 12px;
  }

  .value {
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    color: #8697a8;
  }

  .percent{
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    color: #8697a8;
    margin-top: 7px;
  }

  .loading {
    margin-top: -1px!important;
    margin-right: 8px!important;
    height: 12px;
    width: 12px !important;
  }

}

.tooltipModifiedBy {
  min-width: 200px;
  padding: 16px;

  .title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8697A8;
    margin-bottom: 12px;
  }

  .value {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #324150;
  }

  .margin-top {
    margin-top: 21px;
  }

  .padding-left {
    padding-left: 12px;
  }

  .btnActionTooltip {
    display: flex;
    align-items: center;
    margin-top: 12px;

    span {
      margin-left: 6px;
    }
  }
}

.modifiedDate {
  cursor: default;

  &:hover {
    color: #3547C8;
    text-decoration: underline;
  }
}

.menu-container {
  width: 250px;

  .menu {
    display: flex;
    align-content: center;
    justify-content: space-between;

    .text {
      margin-bottom: 0px;
      user-select: none;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;

      display: flex;
      align-items: center;

      color: #666666;
    }

    .icon {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}

.filterModificadoPor {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 8px;
  }
}

.footerPlotRequest {
  top: 60;
  justify-content: space-between;
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  right: 0;
  height: 68px;
  width: calc(100vw - 227px);
  background: #f8fafb;
  padding-left: 45px;
  padding-right: 70px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  flex: 0 0 50px;
  margin-top: auto;

  .qtyCopies {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #b3c0ce;
  }
}

.deletePlotRowButton {
  cursor: pointer;
  i {
    color: #b3c0ce !important;

    &:hover {
      color: #3547C8 !important;
    }
  }
}

.requestPlotButtonsWrap {
  button {
    margin-left: 15px;
    width: 145px;
  }
}

.labelInputAddress {
  position: relative;
  display: block;

  > span {
    position: absolute;
    background-color: white;
    font-size: 10px;
    top: -7px;
    left: 9px;
    padding: 0 9px;
    color: #bdbdbd;
  }

  > input {
    width: 100%;
    border-radius: 2px;
    padding-left: 10px;
    height: 51px !important;
    background: #ffffff !important;
    border: 1px solid #b3c0ce !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    color: #44566c !important;

    &.valid {
      border: 2px solid #09b66d;
    }

    &.invalid {
      border: 2px solid #e2574c;
    }
  }

  > input:disabled {
    background: #ebeef0 !important;
  }

  > input:focus {
    outline-color: #3547C8;
    &::placeholder {
      color: transparent;
    }
  }

  > input::placeholder {
    color: #bdbdbd;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  i {
    position: absolute;
    left: 12px;
    top: 5px;
  }

  > input:not(:focus):placeholder-shown + span {
    color: transparent;
    background: transparent;
    font-size: 14px;
    top: 0px;
    left: 15px;
  }

  > input:focus + span {
    font-size: 10px;
    top: -7px;
    left: 9px;
    display: block;
    transition: ease 0.2s;
  }

  .label {
    pointer-events: none;
  }
}

.labelInputAddressError {
  position: relative;
  display: block;

  > span {
    position: absolute;
    background-color: white;
    font-size: 10px;
    top: -7px;
    left: 9px;
    padding: 0 9px;
    color: #bdbdbd;
  }

  > input {
    width: 100%;
    border-radius: 2px;
    padding-left: 10px;
    height: 51px !important;
    background: #ffffff !important;
    border: 1px solid red !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    color: #44566c !important;

    &.valid {
      border: 2px solid #09b66d;
    }

    &.invalid {
      border: 2px solid #e2574c;
    }
  }

  > input:disabled {
    background: #ebeef0 !important;
  }

  > input:focus {
    outline-color: #3547C8;
    &::placeholder {
      color: transparent;
    }
  }

  > input::placeholder {
    color: red;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  i {
    position: absolute;
    left: 12px;
    top: 5px;
  }

  > input:not(:focus):placeholder-shown + span {
    color: transparent;
    background: transparent;
    font-size: 14px;
    top: 0px;
    left: 15px;
  }

  > input:focus + span {
    font-size: 10px;
    top: -7px;
    left: 9px;
    display: block;
    transition: ease 0.2s;
  }

  .label {
    pointer-events: none;
  }
}
.ant-select-dropdown-hidden {
  display: block !important;
}

.requestPlotTable {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .qtyCopiesColumn {
    display: flex;
    align-items: center;
    label{
      margin-left: 3px;
    }
    .qtyCopiesField {     
      input {
        height: 23px;
        width: 107px;
        margin-top: 20px;
      }
    }
  }

  thead tr th:first-child {
    width: 55px;
  }

  tbody {
    display: block;
    max-height: calc(100vh - 263px);
    overflow-y: auto;

    tr td:first-child {
      width: 40px;
    }
  }

  @media screen and (max-width: 1366px) {
    .columnNome {
      // width: 25vw;
    }
    .nameCollumn{
      margin-left: 5px;
    }
  }

  @media screen and (min-width: 1367px) {
    .columnNome {
      // width: 50vw;
    }
    .nameCollumn{
      margin-left: 5px;
    }
  }

  .columnCopias,
  .columnCor {
    width: 180px;
  }

  tbody > tr > td.columnCor label{
    margin-left: 3px;
  }
  .columnExclui {
    width: 50px;
  }
}

.secondPlanLoading {
  height: 14px;
  width: 14px !important;
}

.qrCodeSelectIcon {

  ::before {
    background-color: #EAEDF0 !important;
    color: #EAEDF0 !important;
  }
}
