.searchTipo {
  background: #fff !important;  
  margin: 0  !important;
  border-bottom: 1px solid #DADADA;
  border-radius: 0 !important;

  i {
    color: #B0B0B0 !important;
    font-size: 13px !important;
  }

  input {
    background: #fff !important;
  }
}

.tipoOption {
  padding: 14px 0 14px 24px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  .description {
    padding-left: 16px !important;
    font-family: Open Sans;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #44566C !important;
  }

  &:hover {
    background: #F8FAFB;
  }

  .checkbox {
    pointer-events: none;
  }
}

.hide {
  display: none !important;
}