.form-wrapper {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  
  div {

    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 2px;
    }
  }

  .field-main-wrapper{
    display: flex;
    flex-direction: column;

    .field-wrapper {
      display: flex;

      .field-input {
        width: 200px;
        margin-top: 8px;

        &+.field-input {
          margin-left: 5px;
        }
      }
    }
  }
}