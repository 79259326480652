.Container {
    padding-left: 38px;
    width: 100%;

    .tag-text {
        margin-left: 10px;
        margin-bottom: 0px;
        color: #324150;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    
    .clickable {
        cursor: pointer;
    }
}