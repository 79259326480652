.formaInput{
  background: #FFFFFF;
  border: 2px solid #EDEFF2;
  border-radius: 2px
}

.boxDetalhesConta {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;

  .DetalhesContaLeft {
    padding: 20px 0 0 30px;
  }

  .DetalhesContaRight{
    overflow-y: scroll; 
    padding: 0 30px 0 0;
    width: 100%;
  }

  .DetalhesConta{
    padding: 0 30px;
    width: 100%;
  }

  .icon{
    float: right;
    user-select: none;
    cursor: pointer;
  }

  .boxData{
    padding: 20px 30px 0 0;

    .selectPositionCompany {
      width: 100%;
      border: 2px solid #EDEFF2 !important;
      border-radius: 2px !important; 

      > div {
          background: #FFFFFF !important;
          border: none !important;
      }
      > div > div > div[unselectable="on"] {
          top: 1% !important;
          padding-top: 12px!important;
          height: 100% !important;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #44566C;
      }

      > div > div > div {
          padding-top: 12px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #44566C;
          
      }
  }

  label.fields{
      margin-bottom: 10px!important;
    }
    
    .fields {
      input {
        height: 42px !important;
        font-family: Open Sans!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 16px!important;
        color: #44566C!important;
      }
    }
  }

  .title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8697A8;
  }

  .data{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #44566C;
  }
  
  #titleDetails{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    color: #44566C;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
  }
  

  .changeImageWrapper {
    width: 245px;
    height: 174px;
    background: #2EA3F2;
    opacity: 0.75;
    border-radius: 5px;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin-right: 30px;
    background-size: cover;
    background-repeat: no-repeat;

    .changeImageLabel {      
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
    }

    &:hover > span {
      filter: blur(2px);
    }
  }

  .contaImg {
    width: 245px;
    height: 174px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border-radius: 5px;

    span {
      height: 135px;
      width: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .editHover {
      position: absolute;
      color: #FFF;
      display: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      justify-content: center;
      align-items: center;
      flex-flow: column;
  
      p {
        margin-bottom: 0px;
      }
    }
  
    &:hover {
      .editHover {
        display: flex;
      }
  
      > span {
        filter: blur(2px);
      }
    }
  
  }
}

.confirmNewPasswordFeedback {
  height: 50px !important;
  width: 190px !important;

  div {
      width: 100%;
  }
}



