.armazenamentoContainer {
  padding: 27px 36px 23px 36px;
  height: auto;
  overflow: auto;

  .storageGroup {
    height: 70px;
    background-color: #3547C8;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;

    .apiIcon {
      height: 40px;
      width: 40px;
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      top: 15px;
      left: 24px;
      float: left;

      i {
        position: relative;
        left: 8px;
        top: 8px;
      }
    }

    .apiName,
    .apiEmail {
      color: #fff;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
    }

    .apiName {
      position: relative;
      top: 23px;
      left: 44px;
      float: left;
      font-size: 18px;
    }

    .apiEmail {
      text-align: right;
      position: relative;
      top: 23px;
      left: -36px;
      font-size: 14px;
    }
  }

  .storageItem {
    height: 70px;
    background-color: #fff;
    border: 1px solid #eaedf0;
    border-radius: 5px;
    margin-bottom: 10px;

    .obraIcon {
      height: 40px;
      width: 40px;
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      top: 15px;
      left: 24px;
      float: left;

      i {
        position: relative;
        left: 8px;
        top: 8px;
      }
    }

    .obraName {
      color: #44566c;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      position: relative;
      top: 23px;
      left: 44px;
      float: left;
      font-size: 18px;
    }

    .storageItemBtn {
      float: right;
      position: relative;
      top: 22px;
      left: -36px;
      cursor: pointer;
    }
  }
}
