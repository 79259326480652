.ant-tooltip {
  max-width: max-content !important;
}

.ant-tooltip-inner {
  padding: 10px;
  border-radius: 5px;
  border: none;
  
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #666666;
}

.ant-tooltip-arrow {
  display: none;
}