.uploadFileWrapper {
  padding: 21px 26px 15px 26px;
  width: 350px;
  background: #f8fafb;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.uploadFileWrapperAndUpload {
  padding: 21px 26px 15px 26px;
  width: 350px;
  background: #f8fafb;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  position: fixed;
  bottom: 0;
  right: 630px;
  z-index: 10;
}

.headerUploadWrapper {
  display: flex;
  user-select: none;
  cursor: pointer;
  padding-right: 14px;

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #44566c;
    flex: 1;
  }
}

.listFilesWrapper {
  max-height: 320px;
  overflow: auto;
}

.listFilesWrapper > section {
  margin: 0 !important;
}

.fileItemWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 25px 0 25px;
  position: relative;
}

.fileItemDescriptionWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;

  .filenameAndEditWrapper {
    display: flex;
    flex: 1;
    align-items: center;

    .filenameWrapper {
      margin-left: 28px;
      display: flex;
      flex-direction: column;

      .fileName {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #44566c;
        &.hasError {
          color: #e2574c;
        }
      }
      .fileSize {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: #8697a8;
      }
    }

    .showEdit {
      margin-right: 30px;
    }

    .editButton {
      cursor: pointer;
      margin-left: 26px;
    }
  }
}

.formWrapper {
  display: flex;
  align-items: initial;
  margin-bottom: 5px;
}

.nomenclatureFieldsWrapper {
  display: flex;
  overflow: auto;
  margin-right: 49px;
  padding: 3px 0 0 2px;
  flex: 1;

  > span {
    min-width: 100px;
    > label {
      padding-right: 13px;
      > input {
        height: 30px;
        min-width: 90px;
      }
    }
  }

  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 10px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
  }
  scrollbar-color: #555#EEE;
  scrollbar-width: thin;
}

.removeFileButton {
  cursor: pointer;
  user-select: none;
}

.confirmButton {
  padding: 3px 0 0 2px;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  outline: none;
  height: 26px;
  width: 26px;

  &:disabled {
    cursor: default;
    i {
      color: #c4c4c4 !important;
    }
  }
}

.confirmDescriptionButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.openCloudServiceButton {
  height: 26px;
  width: 26px;
  background: #aab2bd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
}

.loading {
  height: 26px;
  width: 26px !important;
}

.radioButtonsWrap {
  padding-left: 5px;
}

.newFileNameOptionsWrap {
  padding-bottom: 10px;

  .titleButtonWrap {
    display: flex;
    padding-bottom: 5px;

    .existFileTitle {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #8697a8;
      flex: 1;
    }
  }

  .newFileNameOption {
    padding-left: 7px;
    .titleOption {
      color: rgb(179, 192, 206);
    }
    cursor: pointer;
  }
  .buttonNewFileName {
    float: right;
  }
}
