.Notification {
  display: flex;
  margin-top: -3px;
  justify-content: space-between;
  align-items: center;

  .messageWrapper {
    display: flex;
    width: 500px;
    height: 30px;
    align-items: center;
    padding-left: 8px;

    strong {
      color: #44566C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      margin-right: 15px;
    }
  
    p {
      color: #44566C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-top: 13px;
      display: contents;
    }

  }
}

.NotificationRequest {
  display: flex;  

  .messageWrapperRequest {
    width: 100%;
    height: 100%;
    padding: 0 13px 0 15px;

    strong {
      color: #44566C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  
    p {
      color: #44566C;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      margin: 0;
    }
  }
}
