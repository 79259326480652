.Container {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-container {
        max-width: 276px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon-container {
            padding: 10px;
            background: #EAEDF0;
            border-radius: 50%;
            margin-bottom: 12px;
        }

        .title {
            color:#324150;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 6px;
        }

        .description {
            color: #8697A8;
            text-align: center;
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}