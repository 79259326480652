.renderTable {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #EAEDF0;

  tr {
    border: 1px solid #EAEDF0;
  }

  th {
    color: #324150;
    font-size: 14px;
    font-weight: 600;
  }
  
  .headTable {
    height: 38px;
    background-color: #EAEDF0;

    .rowCheck {
      padding-left: 0;
      width: 40px;
      text-align: center;
    }
  }

  .rowTable {
    height: 44px;

    td {
      padding-right: 16px;
    }

    .tdCheckLoad {
      width: 40px;
      min-width: 40px;
      padding-right: 0;
    }

    .rowCheckLoad {
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rowName {
      width: 250px;
      padding-right: 16px;
      display: flex;
      align-items: center;
    }
  }
}
