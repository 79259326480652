.obraImg {
  margin-top: 25px;
  height: 135px;
  width: 225px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  border-radius: 5px;

  span {
    height: 135px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .editHover {
    position: absolute;
    color: #fff;
    display: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    p {
      margin-bottom: 0px;
    }
  }

  &:hover {
    .editHover {
      display: flex;
    }

    // > span {
    //   filter: blur(2px);
    // }
  }
}
.obraImgDisabled{
  margin-top: 25px;
  height: 135px;
  width: 225px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
  border-radius: 5px;

  span {
    height: 135px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .editHover {
    position: absolute;
    color: #fff;
    display: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    p {
      margin-bottom: 0px;
    }
  }

}
.obraName {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding-left: 26px;

  &:hover > strong {
    text-decoration: underline;
  }

  strong {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #44566c;

    span {
      color: #fff;
      margin-top: 2px;
    }
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #44566c;
    margin-top: 5px;
  }

  .obraNameSemConexao{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #E2574C;

    span {
      color: #fff;
      margin-top: 2px;
    }
  }
  .semConexao {
    background: #E2574C;
    border-radius: 9.5px;
    width: 100px;
    height: 19;
    padding: 3px;
    line-height: 14px;
    color: #FFFFFF;
    border-radius: 30px;
    text-align:center;
  }

  .wrapperNameAndBadge {
    display: flex;
    align-items: center;
    
    .name {
      color: #324150;
      font-size: 22px;
      font-weight: 700;

      div {
        margin-bottom: 5px;
      }

      span, div {
        color: #324150;
        font-size: 22px;
        font-weight: 700;
      }
    }

    span {
      margin-top: 1px;
    }

    sup {
      margin-left: 18px;
    }
  }
}

.obraAvatar {
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  margin-top: 25px;
  width: 100%;
  padding: 0 24px;

  > span,
  > button {
    margin-right: 4px;
  }

  .buttomUser {
    background: #d7d7d7;
    color: #979797;
    border: #d7d7d7;
    min-width: 28px !important;
    width: 28px;
    height: 28px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #9c9c9c !important;
      color: #383838 !important;
      border-color: transparent !important;
    }

    label {
      font-size: 23px;
      font-weight: bold;
      position: relative;
      cursor: pointer;
    }
  }

  .buttomListUser {
    background: #666 !important;
    color: #fff !important;
    border: #d7d7d7;
    min-width: 28px !important;
    width: 28px;
    height: 28px;
    cursor: default !important;
  }
}

.listSharedUsers {
  display: grid;
  label {
    padding: 1px 3px;
  }
}

.obraItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f8fafb;
  }
}

.opacitySemConexao {
  opacity: 0.25!important;
}
.redSemConexao {

}

.botaoListaUsuarios {
  cursor: pointer;
  margin: 0;
  border-radius: 50%;
}

.semConexaoIcon {
  position: absolute;
  background: rgba(215, 215, 215, 0.4);
  border-radius: 50%;
  height: 30px !important;
  width: 30px !important;
  right: 3px;
  bottom: 3px;
}
