.container {
  padding: 30px 40px 40px 40px;
  overflow: auto;
  max-height: 330px;
}

.titleStorage {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566c;
  padding-bottom: 7px;
}

.titleServiceList {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #44566c;
  padding: 7px 0 3px 0;
}

.serviceContainer {
  display: flex;
  padding-left: 32px;
  margin: 5px 0 0 0;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.active {
    background: rgba(0, 129, 255, 0.05);

    .selectedIcon {
      color: #3547C8;
    }

    .titleService {
      color: #3547C8;
    }
  }

  &.disabled {
    background-color: #f8fafb !important;

    p {
      color: #a2a2a2;
      font-size: 11px;
      background: #eaedf0;
      padding: 3px 10px;
      border-radius: 5px;
      margin-top: 12px;
    }
  }
}

.titleService {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #44566c;
  width: 80px;
  margin-left: 20px;
}

.emailService {
  padding-left: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #44566c;
}

.selectedIcon {
  margin-right: 34px !important;
}

.unselectedIcon {
  margin-right: 39px;
  height: 15px;
  width: 15px;
  border: 1px solid #eaedf0;
  border-radius: 20px;
  margin-left: 4px;
}
