.WrapperTable {
  margin: 0 24px;

  p {
    margin: 0;
  }

  .notificationRow {
    display: flex;
    align-items: center;
    padding-left: 6px;

    a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #44566C;
      padding: 0 14px;
      text-decoration: none;

      &:hover {
        color: #3547C8;
      }
    }

    .badge {
      top: -1px;
      span {
        margin-left: 0;
      }
    }

    .itemName {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 22px;
      color: #8A9AAA;
      top: -2px;
      padding-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 10px;
    }
  }

  .userRow {
    display: flex;
    align-items: center;
    padding-left: 8px;
    
    p {
      margin-left: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .dateRow {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }

  .tableContainer {

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  
    thead tr th:first-child {
      width: 40px !important;
    }
  
    @media screen and (max-width: 1366px) {
      thead tr th:first-child{
        width: 40px !important;
      }
    } 
  
    tbody {
      display: block;
      max-height: calc(100vh - 358px);
      overflow-y: scroll;
  
      tr td:first-child {
        width: 40px !important;
      }

      @media screen and (max-width: 1366px) {
        tr td:first-child {
          width: 40px !important;
        }
      }
    }
  
    @media screen and (max-width: 1366px) {
      .notificationRow, .notificationHeader {
        width: 48vw !important;
      }
      .userRow, .userHeader {
        width: 220px !important;
      }
      .dateRow, .dateHeader {
        width: 120px !important;
      }
    }
  
    @media screen and (min-width: 1367px) {
      .notificationRow, .notificationHeader {
        width: 48vw !important;
      }
      .userRow, .userHeader {
        width: 220px !important;
      }
      .dateRow, .dateHeader {
        width: 120px !important;
      }
    }
  }
}

.space {
  height: 15px;
}

.filterModificadoPor {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 8px;
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}