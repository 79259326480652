.descriptionWrapper {
  padding: 30px 28px 20px 40px;
  div {
    margin-bottom: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #44566C;
  }
}

.button {
  padding: 0 32px !important;
  margin-right: 20px;
  border-radius: 5px !important;
}

.link {
  border-radius: 5px !important;
  padding: 0 32px !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566C;
  cursor: pointer;
  transition: all ease .3s;

  &:hover {
    color: #3547C8;
    text-decoration-line: underline;
  }
}