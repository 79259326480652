.mainHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  width: 16px;
  height: 16px;
}

.iconMoreAction {
  cursor: pointer;
  &:hover {
    background-color: #EAEDF0 !important;
    color: #324150 !important;
  }
}

.MenuDropdown {
  width: 150px;
  padding-top: 4px;
  padding-bottom: 4px;

  .menuItem {
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #324150;
    padding: 15px 10px 15px 15px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 129, 255, 0.1);
    }
  }

  .menuItemRemove:hover {
    background-color: #FBE6E4;

    i {
      color: #E2574C !important;
    }
  }

  i {
    width: 24px;
    margin-right: 10px;
  }
}

.MenuDropdownInvited {
  width: 200px;
}
