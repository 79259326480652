.title-progress {
  font-size: 12px;
  font-weight: 600;
  color: #324150;
}

.description-progress {
  font-size: 12px;
  font-weight: 400;
  color: #8697A8;
  margin-top: 5px;
  margin-bottom: 0;

  b {
    color: #324150;
    font-weight: 600;
  }
}
