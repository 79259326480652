.collapse-enter {
  opacity: 0;
}
.collapse-enter-active {
  opacity: .5;
  transition: opacity 300ms;
}
.collapse-exit {
  opacity: .5;
}
.collapse-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.ant-page-header-heading-title {
  overflow: visible !important;
}