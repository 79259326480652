.WrapperStatus {
  display: flex;
  align-items: center;

  .updateStatus {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 7px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8697A8;
  }

  i {
    width: 14px;
    height: 14px;
  }
}

.WrapperStatusUpdating {
  display: flex;
  align-items: center;

  .updateStatus {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 7px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8697A8;
  }

  i {
    animation: spin 3s linear infinite;
    width: 14px;
    height: 14px;
  
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}