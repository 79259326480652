.disciplinaWrapper {
  padding: 46px 31px 30px 40px;
  display: flex;

  .disciplinaForm {
    width: 100%;
  }
}

.iconSelectWrapper {
  padding: 6px 5px 0 0;
}

.inputDescription {
  input {
    height: 42px;
  }
}
