.MenuItem {
  display: flex;
  justify-content: left;
  min-height: 36px;
  height: 36px;
  padding: 10px 18px 10px 14px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #E2574C;
  user-select: none;
  margin-left: 28px;
  cursor: pointer;

  .icon {
    transform: rotate(180deg);
  }

  .titleMenu {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #E2574C;
  }
}
