.Drawer {
  
  .title {
    display: flex;
    flex-direction: column;

    h1 {
      color: #324150;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
    }

    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #BEC2C6;
    }
  }
  
  .versionSection {
    margin-bottom: 30px;
    padding: 24px 19px 0 19px !important;
  
    .day {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #44566C;
    }
  }
}
