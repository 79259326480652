.breadcrumb {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  color: #8697A8 !important;
  padding-left: 20px !important;
}

@media print {
  .breadcrumb {
    display: none;
  }
}