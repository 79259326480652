.wrapper {
  padding: 30px 0px;
  height: 250px;
  display: flex;


  .imageWrapper {
    float: left;
    margin-top: -70px;
    width: 210px;
    padding: 0px;
    height: 260px;
  }

  .contentWrapper {
    margin-top: 6px;
    margin-left: 10px;
    float: left;
    font-family: "Open Sans", sans-serif;

    .avisoConfirmacaoEnvio {
      color: #3547C8;
      border: 2px solid #3547C8;
      border-radius: 25px;
      padding: 7px 25px;
      font-weight: 600;
      font-size: 12px;
    }

    h2 {
      font-weight: bold;
      font-size: 22px;
      width: 350px;
      line-height: 33px;
      margin-left: 13px;
      margin-top: 30px;
      color: #324150;
      text-align: justify;
    }
  }
}

.button {
  padding: 0 60px !important;
  margin-right: 20px;
  text-align: center;
}
