.TopbarWrapper {
  height: 38px;
  margin: 15px 0;
  width: 100%;
  background: #ffffff;
  border: solid 1px #eaedf0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 6px;
  position: relative;
  align-items: center;

  .TopbarInput {
    display: flex;
    
    .TopbarInputSearch {
      margin-left: 6px;
      margin-right: 36px;
      min-width: 200px;
    }
  }

  .TopBarButtons {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
  }
}

.TableActionTopbarButtonWrapper {
  height: 100%;
  position: relative;
  z-index: 2;

  .TableActionTopbarButton {
    height: 100%;
    padding: 0 12px 0 10px;
    margin: 0 3px 0 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
      background: #f8fafb;
      > span {
        color: #3547C8;
      }
    }

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #8697a8;
      padding-left: 5px;
      user-select: none;
    }
  }

  &:hover {
    background: #f8fafb;

    .titleButton {
      color: #3547C8;
    }
  }

  &.disabled {
    color: #8697a8;
    pointer-events: none;
  }

  &.blocked {
    color: #8697a8;
    cursor: pointer;
  }

  &.disabledObsoleteList {
    color: #8697a8;

    .TableActionTopbarButton {
      cursor: not-allowed;
    }

    &:hover {
      background: #f8fafb;
  
      .titleButton {
        color: #8697a8;
      }
    }
  }
}

.totalSelectedFiles {
  left: 22px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #8697a8;
  position: absolute;
}

.TopbarWrapperFilters {
  margin-bottom: 12px;  
}

@media print {
  .TopbarWrapper {
    display: none;
  }
}
