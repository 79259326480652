.form-wrapper {
  padding: 20px;

  .field-numbers-wrapper {
    display: flex;
    margin-bottom: 20px;

    .field-numbers {
      margin-left: 5px;
    }

    .field-numbers-footer {
      margin-top: -18px;
      justify-self: center;

      &+label {
        margin-left: 5px;
      }
    }

    .selectRole {
      width: 156px;
      margin-right: 5px !important;
    }
  }

  .text-area {
    display: flex;
    justify-content: space-between;
    
    textarea {
      width: 237px;
      height: 200px;
      padding: 10px;
    }
  }
}