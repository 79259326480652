.drawer {
  border-radius: 8px !important;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding-bottom: 16px;
  border-bottom: 1px solid #EAEDF0;
  width: 100%;

  span {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: #324150;
    margin-left: 10px;
    margin-right: 12px;
  }
}

.headerList {
  margin-left: 2px;
  margin-right: -8px;
  height: 32px;
  margin-top: -12px;

  .headerLeftText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #324150;
  } 
  .headerRightText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #8697A8;
  }
}

.childrenCollapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -19px;
  background-color: #FFFFFF;
  
  .childrenPanelText {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    margin: -4px 0; 
    
    &:hover {
      background-color: #F8FAFB;
    }
  }
  
  .childrenPanel {
    border: 0;
    border: none !important;
    background-color: #FFFFFF;

    .firstCamera {
      width: 100%;
      display: flex;
      justify-items: start;
      height: 32px;
      padding-left: 14px;
      border: 0 !important;
      cursor: pointer;

      &:hover {
        background-color: #F8FAFB;
      }

      .firstCameraWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        
        .firstCameraWrapperLeft {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          
          .listLeftText {
            margin-left: 10px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #324150;
          }
        }
      }
    }

    .firstCameraActive {
      background-color: rgba(34, 204, 226, 0.15);
    }
  }
}

.listFolder {

  .childrenCamerasCollapse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px !important;
    background-color: #FFFFFF;
    
    .childrenCamerasPanel {
      border: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      background-color: #FFFFFF;
      border: none !important;
  
      &:hover {
        background-color: #F8FAFB;
      }
  
      .listPanel {
        background-color: #FFFFFF;
  
        .childrenList {
          width: 100%;
          display: flex;
          justify-items: start;
          height: 32px;
          padding-left: 14px;
          border: 0 !important;
          cursor: pointer;
  
          &:hover {
            background-color: #F8FAFB;
          }
  
          .childrenListWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            
            .childrenListWrapperLeft {
              display: flex;
              align-items: center;
              height: 100%;
              width: 100%;
              
              .listLeftText {
                margin-left: 10px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: #324150;
              }
            }
          }
        }
  
        .childrenListActive {
          background-color: rgba(34, 204, 226, 0.15);
        }
      }
    }
  }
}
