.wrapperCTA {
  position: absolute;
  width: 450px;
  height: 140px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  padding: 24px 32px 24px 18px;
  border-radius: 8px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);

  .iconWrapperCTA {
    margin-right: 24px;
  }

  .contentWrapperCTA {

    .firstTextCTA {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #324150;
      margin-bottom: 2px;
    }

    .secondTextCTA {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      color: #8697A8;
      margin-bottom: 12px;
    }
    .button-confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #324150;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 0;
      }
    }
  }

  .btnCloseCTA {
    cursor: pointer;
    padding: 3px;
    margin: 6px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
