.textWrap {
  padding: 40px;

  div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #44566C;
  }

  .margin {
    margin-bottom: 25px;
  }
}

.title-list {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #44566C;
}

.listDisciplineWrap {
  display: flex;
  align-items: center;
  padding-left: 33px;
  padding-right: 50px;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #8697A8;
  }

  .padrigth {
    padding-right: 77px;
  }

  .title {
    padding-left: 15px;
    flex: 1;
    color: #44566C;
  }
  .infoFile{
    text-align: right;
    padding-left: 15px;
    flex: 1;
    color: #8697A8;
  }
}