.contentWrapper {
    padding: 32px;
    height: 100%;
    width: 100%;

    label {
        padding-bottom: 12px;
    }

    label.emailCad {
        margin-top: 30px!important;
    }
}

.title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #44566C;

    padding-bottom: 25px;
}

.link {
    color: #3547C8;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    &:hover {
        text-decoration: underline;
    }
}

.checkbox {
    padding: 0 0 26px 0 !important;
    display: flex !important;
    align-items: flex-start !important;

    > span:first-child {
        vertical-align: super;
        margin-right: 20px;
    }

    &.error {
        > span:first-child {
            border: 2px solid #E2574C;
        }
    }

    > span + span {
        display: inline-block;
        width: 89%;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;

        color: #8697A8;
    }    
}

.hasAccount {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #8697A8;

    text-align: center;
    margin-top: 20px;
}

.passwordFeedback {
    position: absolute;
    right: -120px;
    top: 208px;
}

.confirmPasswordFeedback {
    position: absolute;
    right: 34px;
    top: 295px;

    height: 50px !important;
    width: 190px !important;

    div {
        width: 100%;
    }
}

.selectPositionCompany {
    font-weight: 600 !important;
    font-size: 12px !important;
    outline: none I !important;
    width: 100%;

    > div:first-child {
        border: 2px solid #edeff2 !important;
        border-radius: 2px;
        width: 100%;
    }

    > div {
        outline: none I !important;
        font-size: 12px !important;
        border-color: #edeff2 !important;
    }
}

.powered-by {
    color: #BEC2C6;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    text-align: center;
    margin-top: 30px;

    span {
        font-weight: 700;
    }
}