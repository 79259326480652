.hearderPage > div:first-child {
  padding-left: 2px !important;
}
.breadCrumb {
  margin-left: 5px;
  font-size: 22px !important;
  padding-bottom: 20px;
}
.buttonConvidarUsuarios {
  width: 210px;
  background: #3547C8 !important;
  border-radius: 5px !important;
  color: white !important;
  margin-top: 16px;
  height: 45px !important;
  left: 30px;
}

.buttonConvidarUsuariosDisabled {
  width: 210px;
  background: #8697a8 !important;
  border-radius: 5px !important;
  color: white !important;
  margin-top: 16px;
  height: 45px !important;
  left: 30px;
}
.drowpDownSelector {
  display: flex !important;
  align-items: flex-end !important;
  .selectRole {
    width: 200px !important;
    margin-right: 13px;
    margin-left: 5px;
    
    > div:focus {
      outline-color: #3547C8 !important;
    }
  }

  .selectRoleDisabled {
    width: 200px !important;
    margin-right: 13px;
    margin-left: 5px;
  }
}
.buttonRenviarContainer {
  display: flex;
  align-items: flex-end;
  margin-right: 13px;
  margin-left: 5px;
  .buttonCopiarLink {
    min-width: 37px;
    padding: 0;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconeCopyLink {
    width: 16px;
  }
  .buttonRenviar {
    min-width: 120px;
    border-radius: 5px 0 0 5px !important;
    padding: 0px;
    cursor: pointer;
    &:hover {
      border-radius: 5px 0 0 5px !important;
    }
  }
  .buttonCancelar {
    width: 38px !important;
    max-width: 38px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    font-weight: bold !important;
    border-radius: 0 5px 5px 0 !important;
    &:hover {
      border-radius: 0 5px 5px 0 !important;
    }
    > label {
      cursor: pointer;
    }
  }
}
.tableWrapper {
  margin-right: 29px;
  margin-left: 29px;

  table {
    background-color: hsla(0,0%,89.8%,.3);
  }

  thead > tr > th:first-child {
    padding-left: 24px !important;
  }

  thead,
  tbody > tr > td {
    height: 70px;
  }
}

.columnName {
  display: flex;
  font-size: 14px;
  align-items: center;
  min-width: 400px;
  .columnNameIcon {
    margin-left: 24px;
  }
  > div {
    margin-right: 20px;
  }
  .columnNameName {
    font-weight: 700;
    line-height: 16px;
    color: #324150;
  }
  .columnNameNameInactive {
    color: #8697A8;
  }
  .columnNameEmail {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8697A8;
  }
  .columnNameEmailInactive {
    color: #BEC2C6;
  }
}
.columnStatus {
  margin-right: 20px;
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnObras {
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  width: 250px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnMoreActions {
  margin: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerUserEditIcon {
  display: flex;
  padding: 10px 10px 10px 10px !important;
  cursor: pointer;
  .iconUserEdit {
    padding-right: 20px !important;
    padding-left: 10px !important;
  }
}
.containerUserEdit {
  display: flex;
  padding: 10px 10px 10px 40px !important;
  cursor: pointer;
}
.containerUserRemove {
  display: flex;
  padding: 10px;
  cursor: pointer;
  .iconUserRemove {
    padding-right: 20px !important;
    padding-left: 10px !important;
  }
}
.usuarioItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 51px;
}

.menuColumn {
  width: 150px;
}

.tagItem {

  .tag {
    padding: 2px 12px !important;
    margin: 2px 2px !important;
    cursor: pointer;
  }
}

.tagRender {
  display: flex !important;
  padding: 0 0 0 12px !important;
  align-items: center;
  cursor: pointer !important;
}

.ativo {
  width: 76px;
}

.inativo {
  width: 87px;
}

.tagPending {
  padding: 2px 12px !important;
}