.relativeWrapper {
  width: 100%;
  position: relative;

  .iconClear {
    position: absolute;
    height: auto;
    width: auto;
    top: 0;
    right: 0;
    margin-top: 6px;
    margin-right: 36px;
  
    z-index: 30;
    cursor: pointer;
  }
}

.defaultInput {
  width: 100%;
  height: 32px !important;
  height: auto;
  overflow: visible;
  border: 2px solid #3547C8;
  border-radius: 3px;
  resize: none;
  overflow: hidden;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  color: #324150;
  margin-bottom: 2px;
  margin-top: 2px;
  
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #BEC2C6;
  }
}

.defaultInputError {
  border: 2px solid #E2574C;
  cursor: text !important;
}

.paddingIcon {
  padding-left: 42px !important;
}

.errorInput {
  color: #E2574C;
  padding-left: 10px;
  font-weight: 600;
  height: 18px;
}

.disabledInput {
  color: #8697A8;
}

.noActiveWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 3px;
  padding: 8px 10px;
  overflow: hidden;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
  
  &:hover {
    background-color: #EAEDF0;
  }

  .noActiveText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    color: #324150;
  }
  .noActiveTextSoft {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #BEC2C6;
  }
  .noActiveTextError {
    color: #E2574C;
  }
}

.noActiveWrapperError {
  border: 2px solid #E2574C;
  border-radius: 3px;
  cursor: text;
}

.nPCursor {
  cursor: text;
}
